const getDefaultState = () => {
  return {
    windowsWidth: null,
  };
};

const front = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    /** Guarda el ancho de la pantalla del dispositivo en tiempo real */
    setWindowsWidth(state, { width }) {
      state.windowsWidth = width;
    },
  },
};

export default front;
