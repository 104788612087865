import APIRequest from "./api.services";

export function confirmNewPasswordService(
  parameter,
  successCallback,
  errorCallback,
  finallyCallback
) {
  return APIRequest.post(
    "/api/auth/password/reset/confirm/",
    parameter,
    successCallback,
    errorCallback,
    finallyCallback
  );
}

export function getUser(
  parameter,
  successCallback,
  errorCallback,
  finallyCallback
) {
  return APIRequest.getAxios(
    "/api/auth/user/",
    parameter,
    successCallback,
    errorCallback,
    finallyCallback
  );
}

export function login(
  parameter,
  successCallback,
  errorCallback,
  finallyCallback
) {
  return APIRequest.post(
    "/api/auth/login/",
    parameter,
    successCallback,
    errorCallback,
    finallyCallback
  );
}

export function logout(
  parameter,
  successCallback,
  errorCallback,
  finallyCallback
) {
  return APIRequest.post(
    "/api/auth/logout/",
    parameter,
    successCallback,
    errorCallback,
    finallyCallback
  );
}

export function recoverPassword(
  parameter,
  successCallback,
  errorCallback,
  finallyCallback
) {
  return APIRequest.post(
    "/api/auth/password/reset/",
    parameter,
    successCallback,
    errorCallback,
    finallyCallback
  );
}

export default {
  confirmNewPasswordService,
  getUser,
  login,
  logout,
  recoverPassword,
};
