<template>
  <div class="processing-project-assignment">
    <b-container>
      <b-row>
        <b-col class="pl-0" v-if="windowsWidth < 992">
          <AppSidebar />
        </b-col>
        <b-col :cols="12">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[{ text: 'Inicio', href: '/contractor' }]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row>
            <b-col cols="12"
              ><h1 class="contractor-home__h1 float-left">
                {{ $route.params.contractor_name | remove_dash_and_uppercase }}
              </h1>
            </b-col>
            <b-col cols="12"
              ><b-link class="title-link float-left"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                <span class="text">Acreditación inicial</span></b-link
              ></b-col
            >
          </b-row>

          <b-row>
            <b-col>
              <img
                class="processing-project-assignment__img"
                alt="Proceso de asignarte proyectos"
                src="@/assets/proceso-asignar-proyecto.png"
              />
              <h2>Estamos en proceso de asignarte proyectos</h2>
              <p>
                Te avisaremos por correo electrónico cuando tengas un proyecto
                asignado.
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import { mapState } from "vuex";

export default {
  name: "ProcessingProjectAssignment",
  components: {
    AppSidebar,
  },
  computed: { ...mapState("front", ["windowsWidth"]) },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.processing-project-assignment {
  text-align: center;
  &__h1 {
    margin-top: 20px;
    @media (max-width: $lg) {
      margin-top: 0;
      text-align: left;
    }
  }
  &__img {
    width: 100px;
    margin: 50px auto 30px;
    display: block;
    @media (max-width: $lg) {
      width: 85px;
      margin: 75px auto 29px;
    }
  }
  h2 {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: $dark-grey;
    margin-bottom: 17px;
  }
  p {
    font-size: 15px;
    line-height: 20px;
    color: $dark-grey;
    max-width: 475px;
    display: block;
    margin: 0 auto 50px;
  }
}
</style>
