import router from "@/router";
import authServices from "../services/auth.services";

const getDefaultState = () => {
  return {
    authed: null,
    confirmNewPasswordLoading: false,
    getUserLoading: false,
    loginData: {
      username: "",
      password: "",
      saveData: false,
    },
    loginLoading: false,
    logoutLoading: false,
    passwordRecoveryLoading: false,
    showChangePasswordForm: false,
    showLoginForm: true,
    showPasswordRecoveryForm: false,
    token: "",
    user: {
      company: null,
      email: "",
      first_name: "",
      last_name: "",
      pk: null,
      role: null,
      username: "",
    },
  };
};

const auth = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    token: (state) => state.token,
    user: (state) => state.user,
    userFullName: (state) => {
      if (
        state.user &&
        state.user.first_name &&
        state.user.first_name.length &&
        state.user.first_name.length > 0 &&
        state.user.last_name &&
        state.user.last_name.length &&
        state.user.last_name.length > 0
      ) {
        return `${state.user.first_name} ${state.user.last_name}`;
      } else if (
        state.user &&
        state.user.first_name &&
        state.user.first_name.length &&
        state.user.first_name.length > 0
      ) {
        return `${state.user.first_name}`;
      } else if (
        state.user &&
        state.user.last_name &&
        state.user.last_name.length &&
        state.user.last_name.length > 0
      ) {
        return `${state.user.last_name}`;
      } else {
        return `Usuario sin nombre`;
      }
    },
  },
  mutations: {
    /** Limpia el presente módulo */
    clearState(state) {
      Object.assign(state, getDefaultState());
    },
    /** ¿Está cargando API de obtención de detalles de usuario? */
    setGetUserLoading(state, { getUserLoading }) {
      state.getUserLoading = getUserLoading;
    },
    /** ¿Está cargando API de obtención de detalles de usuario? */
    setConfirmNewPasswordLoading(state, { confirmNewPasswordLoading }) {
      state.confirmNewPasswordLoading = confirmNewPasswordLoading;
    },
    /** ¿Está cargando API de obtención de detalles de usuario? */
    setPasswordRecoveryLoading(state, { passwordRecoveryLoading }) {
      state.passwordRecoveryLoading = passwordRecoveryLoading;
    },
    /** ¿Está cargando API de login? */
    setLoginLoading(state, { loginLoading }) {
      state.loginLoading = loginLoading;
    },
    /** ¿Está cargando API de logout? */
    setLogoutLoading(state, { logoutLoading }) {
      state.logoutLoading = logoutLoading;
    },
    /** Guardar información de login */
    setLoginData(state, { loginData }) {
      state.loginData = loginData;
      localStorage.setItem("usernameM3", loginData.username);
      localStorage.setItem("passwordM3", loginData.password);
      localStorage.setItem("savedM3", loginData.saveData);
    },
    /** Mostrar/ocultar formulario de cambio de contraseña */
    setShowChangePasswordForm(state, { showChangePasswordForm }) {
      state.showChangePasswordForm = showChangePasswordForm;
    },
    /** Mostrar/ocultar formulario de login */
    setShowLoginForm(state, { showLoginForm }) {
      state.showLoginForm = showLoginForm;
    },
    /** Mostrar/ocultar formulario de recuperación de contraseña */
    setShowPasswordRecoveryForm(state, { showPasswordRecoveryForm }) {
      state.showPasswordRecoveryForm = showPasswordRecoveryForm;
    },
    /** Guarda el token de autorización */
    setToken(state, { token }) {
      state.token = token;
    },
    /** Guarda información de usuario logeado */
    setUser(state, { user }) {
      state.user = user;
    },
    setAuthed(state, success) {
      state.authed = success;
    },
  },
  actions: {
    confirmNewPassword(
      { commit },
      { newPassword, newPasswordRepeated, uid, token }
    ) {
      commit("setConfirmNewPasswordLoading", {
        confirmNewPasswordLoading: true,
      });

      return new Promise((resolve, reject) => {
        authServices.confirmNewPasswordService(
          {
            new_password1: newPassword,
            new_password2: newPasswordRepeated,
            uid,
            token,
          },
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          },
          () =>
            commit("setConfirmNewPasswordLoading", {
              confirmNewPasswordLoading: false,
            })
        );
      });
    },
    /**
     * Obtener información del usuario según datos de login
     * @param { commit }
     */
    getUser({ commit }) {
      commit("setGetUserLoading", { loginLoading: true });
      authServices.getUser(
        {},
        ({ data }) => {
          const { role } = data;
          commit("setUser", { user: data });
          switch (role) {
            case 0:
            case 1:
            case 3:
            case 4:
              /** Dirige a vista Administrador */
              router.push({ name: "Dashboard" });
              break;
            case 2:
              /** Dirige a vista Contratista */
              if (data.projects_count >= 1) {
                router.push({ name: "AssignedProjects" });
              } else {
                router.push({ name: "ContractorHome" });
              }
              break;
          }
        },
        () => {},
        () => commit("setGetUserLoading", { loginLoading: false })
      );
    },
    /**
     * Busca si tiene datos guardados de login
     * @param { commit }
     */
    initializeLogin({ commit }) {
      if (
        localStorage.getItem("usernameM3") &&
        localStorage.getItem("passwordM3")
      ) {
        commit("setLoginData", {
          loginData: {
            username: localStorage.getItem("usernameM3"),
            password: localStorage.getItem("passwordM3"),
            saveData: true,
          },
        });
      }
    },
    /**
     * Se realiza login de M3
     * @param { commit, dispatch }
     * @param { email, password }
     */
    login({ commit, dispatch }, { username, password, saved, vm }) {
      commit("setToken", {});
      commit("setUser", {});
      commit("setLoginLoading", { loginLoading: true });
      commit("setAuthed", null);

      authServices.login(
        { username, password },
        ({ data }) => {
          commit("setAuthed", true);
          const { key } = data;
          commit("setToken", { token: `Token ${key}` });
          if (saved) {
            commit("setLoginData", {
              loginData: {
                username,
                password,
                saved,
              },
            });
          } else {
            commit("setAuthed", false);
            dispatch("removeLoginInfo");
          }

          dispatch("getUser");
        },
        (err) => {
          if (err.response.status === 400) {
            vm.$bvToast.toast(
              "No se pudo autentificaer. Favor, revisar el usuario y/o contraseña.",
              {
                title: "Autentificación",
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              }
            );
          }
          commit("setToken", {});
        },
        () => commit("setLoginLoading", { loginLoading: false })
      );
    },
    /**
     * Se realiza logout de M3
     * @param { commit }
     */
    logout({ commit }) {
      commit("setLogoutLoading", { logoutLoading: true });
      authServices.logout(
        {},
        () => {
          // localStorage.removeItem("token");
          router.push({ name: "Login" });
        },
        () => {},
        () => commit("setLogoutLoading", { logoutLoading: false })
      );
    },
    /**
     * Se realiza logout de M3
     * @param { commit }
     */
    passwordRecovery({ commit }, { email }) {
      commit("setPasswordRecoveryLoading", { passwordRecoveryLoading: true });

      return new Promise((resolve, reject) => {
        authServices.recoverPassword(
          { email },
          () => {
            resolve();
          },
          () => {
            commit("setPasswordRecoveryLoading", {
              passwordRecoveryLoading: false,
            });
            reject();
          },
          () =>
            commit("setPasswordRecoveryLoading", {
              passwordRecoveryLoading: false,
            })
        );
      });
    },
    /**
     * Remueve datos de login guardados
     * @param { commit }
     */
    removeLoginInfo({ commit }) {
      commit("setLoginData", {
        loginData: {
          username: "",
          password: "",
          saveData: false,
        },
      });
      localStorage.removeItem("usernameM3");
      localStorage.removeItem("passwordM3");
      localStorage.removeItem("savedM3");
    },
    /**
     * Esconde formulario de login y muestra formulario de recuperación de contraseña
     * @param { commit }
     */
    toggleLoginFormPasswordRecoveryFormChangePasswordForm(
      { commit },
      { showChangePasswordForm, showLoginForm, showPasswordRecoveryForm }
    ) {
      commit("setShowChangePasswordForm", { showChangePasswordForm });
      commit("setShowLoginForm", { showLoginForm });
      commit("setShowPasswordRecoveryForm", { showPasswordRecoveryForm });
    },
  },
};

export default auth;
