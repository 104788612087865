<template>
  <b-card
    title="Cambiar contraseña"
    class="login__card login__card--password login__card--change-password"
  >
    <p>
      Ingresa tu nueva contraseña y luego repite el ingreso. Asegúrate que ambas
      contraseñas sean iguales
    </p>
    <b-form @submit="onSubmit">
      <b-form-group id="input-group-change-password">
        <label label-for="input-change-password"
          >Ingresa nueva contraseña</label
        >
        <b-form-input
          id="input-change-password"
          type="password"
          placeholder="••••"
          v-model="$v.form.password.$model"
          :state="validateState('password')"
        ></b-form-input
        ><b-form-invalid-feedback id="input-change-password-live-feedback"
          >Ingresa la contraseña
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-change-repeated-password">
        <label label-for="input-change-repeated-password"
          >Repite nueva contraseña</label
        >
        <b-form-input
          id="input-change-repeated-password"
          type="password"
          placeholder="••••"
          v-model="$v.form.repeatedPassword.$model"
          :state="validateState('repeatedPassword')"
        ></b-form-input
        ><b-form-invalid-feedback
          id="input-change-repeated-password-live-feedback"
          >Ingresa la contraseña
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="login__card__actions">
        <b-button
          type="submit"
          variant="primary"
          :disabled="
            confirmNewPasswordLoading ||
            $v.form.password.$invalid ||
            $v.form.repeatedPassword.$invalid
          "
          >Crear nueva contraseña<b-spinner
            v-if="confirmNewPasswordLoading"
            small
            label="Loading..."
          ></b-spinner
        ></b-button>
        <b-link @click="onToggleLoginFormPasswordRecoveryFormChangePasswordForm"
          >Cancelar</b-link
        >
      </div>
    </b-form>
  </b-card>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ChangePasswordForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        password: "",
        repeatedPassword: "",
      },
      token: null,
      uid: null,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(3),
      },
      repeatedPassword: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    ...mapState("auth", ["confirmNewPasswordLoading"]),
  },
  mounted() {
    this.setConfirmNewPasswordLoading({ confirmNewPasswordLoading: false });
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get("token");
    this.uid = urlParams.get("uid");
  },
  methods: {
    ...mapActions("auth", [
      "confirmNewPassword",
      "toggleLoginFormPasswordRecoveryFormChangePasswordForm",
    ]),
    ...mapMutations("auth", ["setConfirmNewPasswordLoading"]),
    /** Realiza las validaciones del formulario */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /** Resetea la información del formulario */
    resetForm() {
      this.form = {
        password: null,
        repeatedPassword: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    /** Envía la información ingresada al formulario */
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.confirmNewPassword({
        newPassword: this.$v.form.password.$model,
        newPasswordRepeated: this.$v.form.repeatedPassword.$model,
        uid: this.uid,
        token: this.token,
      });
    },

    onToggleLoginFormPasswordRecoveryFormChangePasswordForm() {
      this.$router.resolve("/", "", "/");
      this.toggleLoginFormPasswordRecoveryFormChangePasswordForm({
        showChangePasswordForm: false,
        showLoginForm: true,
        showPasswordRecoveryForm: false,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.login {
  &__card {
    &--change-password {
      &.card {
        @media (min-width: $lg-min) {
          height: 497px;
        }
      }
    }
  }
}
</style>
