<template>
  <div id="app">
    <AppHeader />
    <router-view :key="this.$route.path" />
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import AppHeader from "./components/AppHeader.vue";

export default {
  name: "App",
  components: {
    AppHeader,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations("front", ["setWindowsWidth"]),
    /**
     * Obtener ancho del sitio
     */
    handleResize() {
      const winWidth = window.innerWidth;
      this.setWindowsWidth({ width: winWidth });
    },
  },
};
</script>
