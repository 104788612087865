<template>
  <div class="dashboard">
    <b-container fluid>
      <b-row>
        <b-col class="pl-0">
          <AppSidebar />
        </b-col>
        <b-col :cols="windowsWidth > 991 ? 9 : 12">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[{ text: 'Inicio', href: '#' }]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row>
            <b-col cols="12"><h1 class="float-left">Dashboard</h1></b-col>
          </b-row>

          <b-row>
            <b-col cols="6" lg="3">
              <b-card class="dashboard__card">
                <b-card-title>Proyectos activos</b-card-title>
                <b-card-text>
                  <img
                    alt="Ícono proyectos activos"
                    src="@/assets/icon-proyectos-activos.png"
                    width="30"
                  />
                  {{ summary.projects_count }}
                </b-card-text>
              </b-card>
            </b-col>
            <!--<b-col cols="6" lg="3">
              <b-card class="dashboard__card">
                <b-card-title>Contratistas acreditados</b-card-title>
                <b-card-text>
                  <img
                    alt="Ícono contratistas acreditados"
                    src="@/assets/icon-contratistas-acreditados.png"
                    width="30"
                  />
                  {{ summary.contractors_active_count }}/{{
                    summary.contractors_count
                  }}
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="6" lg="3">
              <b-card class="dashboard__card">
                <b-card-title>Cargas mes actual</b-card-title>
                <b-card-text>
                  <img
                    alt="Ícono cargas mes actual"
                    src="@/assets/icon-cargas-mes-actual.png"
                    width="30"
                  />
                  {{ summary.document_counts }}
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="6" lg="3">
              <b-card class="dashboard__card">
                <b-card-title>Cargas pendientes</b-card-title>
                <b-card-text>
                  <img
                    alt="Ícono cargas pendientes"
                    src="@/assets/icon-cargas-pendientes.png"
                    width="30"
                  />
                  {{ summary.document_pending_counts }}
                </b-card-text>
              </b-card>
            </b-col>-->
          </b-row>

          <b-row class="mt-4">
            <b-col><h2 class="text-left left-line">Filtros</h2></b-col>
          </b-row>

          <b-form inline class="row">
            <b-col cols="12" lg="3">
              <b-form-input
                v-model="filters.keywords"
                placeholder="Buscar..."
              ></b-form-input>
            </b-col>
            <!--<b-col cols="12" lg="3">
              <b-form-select
                v-model="filters.period"
                :options="filterPeriod"
              ></b-form-select>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-select
                v-model="filters.status"
                :options="filterStatus"
              ></b-form-select>
            </b-col>-->
            <b-col cols="12" lg="3">
              <div class="form-inline__actions">
                <b-link class="uppercase-link align-middle">Limpiar</b-link>
                <b-button @click="search" variant="primary" class="float-right"
                  >Filtrar</b-button
                >
              </div>
            </b-col>
          </b-form>

          <b-row class="mt-5">
            <b-col><h2 class="text-left left-line">Contratistas</h2></b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table
                hover
                :items="dashboardItems"
                :fields="fields"
                :stacked="windowsWidth < 992"
                class="mt-2"
                id="dashboard-table"
                :per-page="itemsPerPage"
                :current-page="currentPage"
                show-empty
              >
                <template #empty="">No existen contratistas asignados</template>
                <template #table-busy>
                  <div class="text-center my-4">
                    <b-spinner class="align-middle" medium></b-spinner>
                  </div>
                </template>
                <template #cell(contractor)="data">
                  <span class="first-text">{{
                    data.item.contractor.name
                  }}</span>
                  <span class="second-text">{{ data.item.project.name }}</span>
                </template>
                <template #cell(initial_accreditation)="data">
                  <StatusTableCell
                    :table-data-item="data.item.initial_accreditation"
                  />
                </template>
                <template #cell(previous_months)="data">
                  <StatusTableCell
                    :table-data-item="data.item.previous_months"
                  />
                </template>
                <template #cell(current_month)="data">
                  <StatusTableCell :table-data-item="data.item.current_month" />
                </template>
                <template #cell(actions)="data">
                  <b-link
                    class="table__action-link"
                    :to="{
                      name: 'Documents',
                      params: {
                        contractor_slug: data.item.contractor.slug,
                        project_slug: data.item.project.slug,
                      },
                    }"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path
                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                      />
                    </svg>
                    <span>Ver detalle</span></b-link
                  >
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="itemsPerPage"
                aria-controls="dashboard-table"
                pills
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AppSidebar from "@/components/AppSidebar.vue";
import StatusTableCell from "@/components/StatusTableCell.vue";
import tablesServices from "@/services/tables.services";
import { mapState } from "vuex";

export default {
  name: "Dashboard",
  components: {
    AppSidebar,
    StatusTableCell,
  },
  data() {
    return {
      currentPage: 1,
      filters: {
        keywords: "",
        period: null,
        status: null,
      },
      filterPeriod: [
        { text: "Todos los periodos", value: null },
        { text: "Mes actual", value: "current" },
        { text: "Mes anterior", value: "previous" },
      ],
      filterStatus: [
        { text: "Todos los estados", value: null },
        { text: "Aprobados", value: "approved" },
        { text: "Rechazados", value: "rejected" },
        { text: "Pendientes", value: "pending" },
      ],
      fields: [
        {
          key: "contractor",
          sortable: true,
        },
        {
          key: "initial_accreditation",
          label: "Acreditación inicial",
        },
        {
          key: "previous_months",
          label: "Meses anteriores",
        },
        {
          key: "current_month",
          label: "Mes en curso",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],
      show: true,
      windowWidth: null,
      dashboardItems: [],
      summary: {},
    };
  },
  computed: {
    ...mapState("tables", ["itemsPerPage"]),
    ...mapState("front", ["windowsWidth"]),
    rows() {
      return this.dashboardItems.length;
    },
  },
  mounted() {
    //this.getDashboardInfo({ slug: null, filters: {} });

    Promise.all([
      tablesServices.getSummary(),
      tablesServices.getDashboardInfo({ slug: null, filters: {} }),
    ])
      .then((values) => {
        this.summary = values[0];
        this.dashboardItems = values[1];
      })
      .catch(() => {
        this.$bvToast.toast("No se pudo cargar los datos del dashboard", {
          title: "Autentificación",
          toaster: "b-toaster-top-right",
          solid: true,
          appendToast: false,
          variant: "danger",
        });
      });
  },
  methods: {
    search() {
      tablesServices
        .getDashboardInfo({
          slug: null,
          filters: this.filters,
        })
        .then((items) => {
          this.dashboardItems = items;
        });
    },
  },
};
</script>
<style lang="scss"></style>
