import tablesServices from "../services/tables.services";

const getDefaultState = () => {
  return {
    dashboardItems: [],
    summary: {},
    documents: [],
    project: {},
    contractor: {},
    itemsPerPage: 10,
    loadingGetDashboardInfo: false,
    loadingGetDocuments: false,
    loadingGetProjects: false,
    projects: [],
  };
};

const tables = {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    setDashboardItems(state, { dashboardItems }) {
      state.dashboardItems = dashboardItems;
    },
    setDocuments(state, { documents }) {
      state.documents = documents;
    },
    setLoadingGetDashboardInfo(state, { loadingGetDashboardInfo }) {
      state.loadingGetDashboardInfo = loadingGetDashboardInfo;
    },
    setLoadingGetDocuments(state, { loadingGetDocuments }) {
      state.loadingGetDocuments = loadingGetDocuments;
    },
    setLoadingGetProjects(state, { loadingGetProjects }) {
      state.loadingGetProjects = loadingGetProjects;
    },
    setProjects(state, { projects }) {
      state.projects = projects;
    },
    setProject(state, { project }) {
      state.project = project;
    },
    setContractor(state, { contractor }) {
      state.contractor = contractor;
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
  },
  actions: {
    getDashboardInfo({ commit }, { slug, filters }) {
      commit("setLoadingGetDashboardInfo", { loadingGetDashboardInfo: true });

      tablesServices.getDashboardInfo(
        { slug, filters },
        ({ data }) => {
          commit("setDashboardItems", { dashboardItems: data });

          tablesServices.getSummary(({ data }) => {
            commit("setSummary", data);
          });
        },
        () => {},
        () =>
          commit("setLoadingGetDashboardInfo", {
            loadingGetDashboardInfo: false,
          })
      );
    },
    getDocuments({ commit }, { slug, filters }) {
      return new Promise((resolve, reject) => {
        commit("setLoadingGetDocuments", { loadingGetDocuments: true });

        tablesServices.getDocuments(
          { project_slug: slug, filters },
          ({ data }) => {
            // eslint-disable-next-line no-unused-vars
            const { count, pages, results } = data;
            commit("setDocuments", { documents: results });
            resolve(data);
          },
          (error) => reject(error),
          () =>
            commit("setLoadingGetDocuments", {
              loadingGetDocuments: false,
            })
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    archiveProject({ commit }, { projectSlug, active }) {
      return new Promise((resolve, reject) => {
        tablesServices.archiveProject(
          { projectSlug, active },
          ({ data }) => {
            resolve(data);
          },
          (error) => reject(error)
        );
      });
    },
    getProject({ commit }, slug) {
      return new Promise((resolve, reject) => {
        commit("setLoadingGetDocuments", { loadingGetDocuments: true });
        tablesServices.getProject(
          { slug: slug },
          ({ data }) => {
            commit("setProject", { project: data });
            resolve(data);
          },
          (err) => reject(err),
          () =>
            commit("setLoadingGetDocuments", {
              loadingGetDocuments: false,
            })
        );
      });
    },
    getContractor({ commit }, slug) {
      return new Promise((resolve, reject) => {
        commit("setLoadingGetDocuments", { loadingGetDocuments: true });
        tablesServices.getContractor(
          { slug: slug },
          ({ data }) => {
            commit("setContractor", { contractor: data });
            resolve();
          },
          (err) => reject(err),
          () =>
            commit("setLoadingGetDocuments", {
              loadingGetDocuments: false,
            })
        );
      });
    },
    getProjects({ commit }) {
      commit("setLoadingGetProjects", { loadingGetProjects: true });
      tablesServices.getProjects(
        {},
        ({ data }) => {
          commit("setProjects", { projects: data });
        },
        () => {},
        () =>
          commit("setLoadingGetProjects", {
            loadingGetProjects: false,
          })
      );
    },
  },
};

export default tables;
