<template>
  <b-card title="Accede a tu cuenta" class="login__card">
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="input-group-username"
        label="Nombre de usuario o rut"
        label-for="input-username"
      >
        <b-form-input
          id="input-username"
          placeholder="Ej: nombre@gmail.com"
          v-model="$v.formLoginData.username.$model"
          :state="validateState('username')"
        ></b-form-input>
        <b-form-invalid-feedback id="input-username-live-feedback"
          >Mensaje
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-password">
        <label label-for="input-password"
          >Contraseña
          <b-link
            @click="
              toggleLoginFormPasswordRecoveryFormChangePasswordForm({
                showChangePasswordForm: false,
                showLoginForm: false,
                showPasswordRecoveryForm: true,
              })
            "
            >Olvidé mi contraseña</b-link
          ></label
        >
        <b-form-input
          id="input-password"
          type="password"
          placeholder="••••"
          v-model="$v.formLoginData.password.$model"
          :state="validateState('password')"
        ></b-form-input
        ><b-form-invalid-feedback id="input-password-live-feedback"
          >Ingresa la contraseña
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-checkbox
        v-model="$v.formLoginData.saveData.$model"
        size="md"
        switch
      >
        Recordar mis datos de ingreso
      </b-form-checkbox>

      <b-button
        :disabled="
          getUserLoading ||
          loginLoading ||
          $v.formLoginData.password.$invalid ||
          $v.formLoginData.username.$invalid
        "
        type="submit"
        variant="primary"
        >Iniciar sesión<b-spinner
          v-if="getUserLoading || loginLoading"
          small
          label="Loading..."
        ></b-spinner
      ></b-button>
    </b-form>
  </b-card>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default {
  name: "LoginForm",
  mixins: [validationMixin],
  computed: {
    ...mapState("auth", ["getUserLoading", "loginLoading", "loginData"]),
    formLoginData: {
      get() {
        return this.loginData;
      },
      set(newLoginData) {
        this.$v.formLoginData.$touch();
        return newLoginData;
      },
    },
  },
  validations: {
    formLoginData: {
      username: {
        required,
        minLength: minLength(3),
      },
      password: {
        required,
        minLength: minLength(5),
      },
      saveData: {},
    },
  },
  created() {
    this.initializeLogin();
  },
  methods: {
    ...mapActions("auth", [
      "initializeLogin",
      "login",
      "toggleLoginFormPasswordRecoveryFormChangePasswordForm",
    ]),

    /** Realiza las validaciones del formulario */
    validateState(name) {
      const { $dirty, $error } = this.$v.formLoginData[name];
      return $dirty ? !$error : null;
    },

    /** Envía la información ingresada al formulario */
    onSubmit() {
      this.$v.formLoginData.$touch();
      if (this.$v.formLoginData.$anyError) {
        return;
      }
      /** Llama función login de store */
      this.login({
        username: this.$v.formLoginData.username.$model,
        password: this.$v.formLoginData.password.$model,
        saved: this.$v.formLoginData.saveData.$model,
        vm: this,
      });
    },
  },
};
</script>
