<template>
  <div class="sidebar">
    <b-sidebar v-if="windowsWidth < 992" id="mobile-sidebar" backdrop>
      <template #title=""
        ><b-navbar-nav>
          <b-nav-text>{{ userFullName }}</b-nav-text>
          <b-nav-item @click="logout" class="logout">Cerrar sesión</b-nav-item>
        </b-navbar-nav></template
      >
      <b-nav vertical v-if="!isCountractorRoute">
        <b-nav-item
          v-if="!isTechnicalInspectorRoute"
          class="dashboard"
          :class="{ 'dashboard-active': $route.name === 'Dashboard' }"
          active-class="router-link-active"
          @click="changeRoute('dashboard')"
          :to="{
            name: 'Dashboard',
          }"
          >Dashboard</b-nav-item
        >
        <b-nav-item
          v-for="project in projects"
          :key="project.id"
          class="project-item"
          active-class="router-link-active"
          @click="changeRoute(project.slug)"
          :to="{
            name: 'Project',
            params: {
              project_name: project.slug,
            },
          }"
          >{{ project.name }}</b-nav-item
        >
        <b-nav-item
          v-if="!isTechnicalInspectorRoute"
          class="administrator"
          href="https://buku.cl/admin"
          target="_blank"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-gear-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"
            /></svg
          ><span>Administrador s</span></b-nav-item
        >
      </b-nav>
    </b-sidebar>

    <b-nav v-else vertical>
      <b-nav-item
        v-if="!isTechnicalInspectorRoute"
        class="dashboard"
        active-class="router-link-active"
        @click="changeRoute('dashboard')"
        :to="{
          name: 'Dashboard',
          params: { project_name: '' },
        }"
        >Dashboard</b-nav-item
      >
      <b-nav-item
        v-for="project in projects"
        :key="project.id"
        class="project-item"
        active-class="router-link-active"
        @click="changeRoute(project.slug)"
        :to="{
          name: 'Project',
          params: {
            project_slug: project.slug,
          },
        }"
        >{{ project.name }}</b-nav-item
      >
      <b-nav-item
        v-if="isAdmin"
        class="administrator"
        href="https://buku.cl/admin"
        target="_blank"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-gear-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"
          /></svg
        ><span>Administrador</span></b-nav-item
      >
    </b-nav>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import tablesServices from "@/services/tables.services";

export default {
  name: "AppSidebar",
  computed: {
    ...mapGetters("auth", ["userFullName"]),
    ...mapState("auth", ["user"]),
    ...mapState("front", ["windowsWidth"]),
    isCountractorRoute() {
      return this.$route.matched.some(
        ({ name }) =>
          name === "ContractorHome" ||
          name === "InitialAccreditationForm" ||
          name === "AssignedProjects" ||
          name === "ProjectDocuments" ||
          name === "UploadDocumentForm" ||
          name === "EditDocumentForm"
      );
    },
    isAdmin() {
      return this.user.role == 0;
    },
    isTechnicalInspectorRoute() {
      return this.$route.matched.some(
        ({ name }) =>
          name === "ProjectTechnicalInspector" ||
          name === "AssignedContractorsTechnicalInspector"
      );
    },
  },
  data() {
    return {
      projects: [],
    };
  },
  mounted() {
    tablesServices.getProjects().then((projects) => {
      this.projects = projects;
    });
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("tables", ["getDashboardInfo"]),
    changeRoute(slug) {
      if (slug !== "dashboard") {
        this.getDashboardInfo({ slug: slug, filters: {} });
      } else {
        this.getDashboardInfo({ slug: null, filters: {} });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.sidebar {
  background-color: #eee;
  height: 100%;
  @media (min-width: $lg-min) {
    min-height: calc(100vh - 51.33px);
    padding: 20px 0 0;
  }
  .b-sidebar {
    > .b-sidebar-header {
      box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
      padding: 7px 19px 9px;
      z-index: 1;
      .navbar-nav {
        .navbar-text {
          font-size: 16px;
          line-height: 25px;
          color: #666666;
          padding-top: 0;
          padding-bottom: 0;
        }
        .nav-item .nav-link {
          margin-left: 0;
          padding-left: 0;
          font-weight: normal;
          text-decoration: underline;
          padding-top: 3px;
          padding-bottom: 0;
        }
      }
      .close {
        opacity: 1;
        font-size: 34px;
        svg {
          color: $primary-color;
        }
      }
    }
    .b-sidebar-body {
      background-color: #f6f6f5;
      padding-top: 10px;
    }
  }
  .nav-item {
    .nav-link {
      font-size: 16px;
      line-height: 20px;
      color: $dark-grey;
      text-align: left;
      padding: 9px 10px;
      margin: 0 10px;
      .highlight {
        width: 8px;
        height: 8px;
        background-color: $primary-color;
        border-radius: 50%;
        margin-left: 15px;
        display: inline-block;
      }
    }
    &:hover {
      .nav-link {
        color: darken($dark-grey, 25%);
        transition: color 0.1s ease-in;
      }
    }
  }
  .administrator {
    background-color: $dark-grey;
    .nav-link {
      color: white;
      padding: 13px 10px;
      span,
      svg {
        color: white;
      }
      svg {
        margin-right: 16px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.dashboard {
  .nav-item {
    &.dashboard:not(.project-item) {
      .router-link-active {
        background-color: $primary-color-lighter;
        color: white !important;
        font-weight: bold;
        border-radius: 5px;
      }
    }
  }
}
.project,
.assigned-contractors {
  .nav-item {
    &.project-item:not(.dashboard) {
      .router-link-active {
        background-color: $primary-color-lighter;
        color: white !important;
        font-weight: bold;
        border-radius: 5px;
      }
    }
  }
}
</style>
