<template>
  <b-button
    v-b-tooltip.hover
    class="comment-icon-component remove-btn-styling"
    title="Comentarios"
    @click="loadComment"
  >
    <comment-text-icon></comment-text-icon>
    <span class="comment-icon-component__amount">{{
      numberOfComments > 0 ? numberOfComments : ""
    }}</span>
    <span class="comment-icon-component__circle"></span>
  </b-button>
</template>
<script>
import CommentTextIcon from "vue-material-design-icons/CommentText.vue";

export default {
  name: "CommentIconComponent",
  components: {
    CommentTextIcon,
  },
  props: {
    documentId: {
      type: Number,
    },
    numberOfComments: {
      type: Number,
    },
  },
  methods: {
    loadComment() {
      this.$emit("loadComments", this.documentId);
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.comment-icon-component {
  position: relative;
  &.btn-secondary:not(:disabled):not(.disabled) {
    &:active {
      color: $primary-color;
      background-color: transparent;
      border-color: transparent;
    }
    &:focus {
      color: $primary-color;
      box-shadow: 0 0 0 0.2rem rgb(222 169 170 / 25%);
    }
  }
  &__amount {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
    padding-left: 5px;
  }
  &__circle {
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: $success-color;
    border: 1px solid white;
    border-radius: 50%;
    left: 19px;
    top: -4px;
  }
}
</style>
