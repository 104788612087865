import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth";
import comments from "./comments";
import documents from "./documents";
import front from "./front";
import tables from "./tables";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    auth,
    comments,
    documents,
    front,
    tables,
  },
});
