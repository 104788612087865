<template>
  <div class="project">
    <b-container fluid>
      <b-row v-if="project">
        <b-col class="pl-0">
          <AppSidebar />
        </b-col>
        <b-col :cols="windowsWidth > 991 ? 9 : 12">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[
                  { text: 'Inicio', to: { name: 'Dashboard' } },
                  {
                    text: project.name,
                  },
                ]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="8" class="order-2 order-lg-1"
              ><h1 class="float-left">
                {{ project.name }}
                <b-link
                  v-if="project.active"
                  @click="handleArchiveProject(false)"
                  class="title-link"
                  ><archive-arrow-down-icon></archive-arrow-down-icon>
                  <span class="text">Archivar proyecto</span></b-link
                >
                <b-link
                  v-if="!project.active"
                  @click="handleArchiveProject(true)"
                  class="title-link"
                  ><archive-arrow-down-icon></archive-arrow-down-icon>
                  <span class="text">Desarchivar proyecto</span></b-link
                >
              </h1>
              <p class="project-title-meta">
                Arquitecto: Alejandro Ríos | Las Condes, RM
              </p></b-col
            >
          </b-row>

          <b-row class="mt-4">
            <b-col><h2 class="text-left left-line">Filtros</h2></b-col>
          </b-row>

          <b-form inline class="row">
            <b-col cols="12" lg="3">
              <b-form-input
                v-model="filters.keywords"
                placeholder="Buscar..."
              ></b-form-input>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-select
                v-model="filters.period"
                :options="filterPeriod"
              ></b-form-select>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-select
                v-model="filters.status"
                :options="filterStatus"
              ></b-form-select>
            </b-col>
            <b-col cols="12" lg="3">
              <div class="form-inline__actions">
                <b-link class="uppercase-link align-middle">Limpiar</b-link>
                <b-button @click="search" variant="primary" class="float-right"
                  >Filtrar</b-button
                >
              </div>
            </b-col>
          </b-form>

          <b-row class="mt-5">
            <b-col
              ><h2 class="text-left left-line">
                Contratistas asignados
              </h2></b-col
            >
          </b-row>

          <b-row>
            <b-col>
              <b-table
                hover
                id="projects-table"
                :items="projects"
                :fields="fields"
                :stacked="windowsWidth < 992"
                :busy="loading"
                :per-page="itemsPerPage"
                :current-page="currentPage"
                class="mt-2"
                show-empty
              >
                <template #empty="">No existen contratistas asignados</template>
                <template #table-busy>
                  <div class="text-center my-4">
                    <b-spinner class="align-middle" medium></b-spinner>
                  </div>
                </template>
                <template #cell(contractor)="data">
                  <span class="first-text">{{
                    data.item.contractor.name
                  }}</span>
                  <span class="second-text">{{ data.item.project.name }}</span>
                </template>
                <template #cell(initial_accreditation)="data">
                  <StatusTableCell
                    :table-data-item="data.item.initial_accreditation"
                  />
                </template>
                <template #cell(previous_months)="data">
                  <StatusTableCell
                    :table-data-item="data.item.previous_months"
                  />
                </template>
                <template #cell(current_month)="data">
                  <StatusTableCell :table-data-item="data.item.current_month" />
                </template>
                <template #cell(actions)="data">
                  <b-link
                    class="table__action-link"
                    :to="{
                      name: 'Documents',
                      params: {
                        contractor_slug: data.item.contractor.slug,
                        project_slug: project.slug,
                      },
                    }"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path
                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                      />
                    </svg>
                    <span>Ver detalle</span></b-link
                  >
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="itemsPerPage"
                aria-controls="projects-table"
                pills
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import ArchiveArrowDownIcon from "vue-material-design-icons/ArchiveArrowDown.vue";
import StatusTableCell from "@/components/StatusTableCell.vue";
import { mapState, mapMutations } from "vuex";
import tablesServices from "@/services/tables.services";

export default {
  name: "Project",
  components: {
    AppSidebar,
    ArchiveArrowDownIcon,
    StatusTableCell,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      project: null,
      projects: [],
      filters: {
        keywords: "",
        period: null,
        status: null,
      },
      filterPeriod: [
        { text: "Todos los periodos", value: null },
        { text: "Mes actual", value: "current" },
        { text: "Mes anterior", value: "previous" },
      ],
      filterStatus: [
        { text: "Todos los estados", value: null },
        { text: "Aprobados", value: "approved" },
        { text: "Rechazados", value: "rejected" },
        { text: "Pendientes", value: "pending" },
      ],
      fields: [
        {
          key: "contractor",
          sortable: true,
        },
        {
          key: "initial_accreditation",
          label: "Acreditación inicial",
        },
        {
          key: "previous_months",
          label: "Meses anteriores",
        },
        {
          key: "current_month",
          label: "Mes en curso",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],
    };
  },
  computed: {
    ...mapState("front", ["windowsWidth"]),
    ...mapState("tables", ["itemsPerPage"]),
    rows() {
      return this.projects.length;
    },
  },
  mounted() {
    this.resetFilter();
    tablesServices
      .getProject(this.$route.params.project_slug)
      .then((project) => (this.project = project));

    this.search();
  },
  methods: {
    ...mapMutations("documents", ["resetFilter"]),
    search() {
      tablesServices
        .getDashboardInfo({
          slug: this.$route.params.project_slug,
          filters: this.filters,
        })
        .then((projects) => (this.projects = projects));
    },
    handleArchiveProject(active) {
      let text = "archivar";
      if (active) {
        text = "desarchivar";
      }
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de ${text} este projecto "${this.project.name}"`,
          {
            okTitle: "Confirmar",
            cancelTitle: "Cancelar",
          }
        )
        .then((value) => {
          if (value) {
            tablesServices
              .archiveProject({
                projectSlug: this.$route.params.project_slug,
                active,
              })
              .then(() => {
                this.project.active = active;
                this.search();
              });
          }
        });
    },
  },
};
</script>
<style lang="scss"></style>
