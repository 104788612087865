<template>
  <div class="project">
    <b-container>
      <b-row>
        <b-col class="pl-0" v-if="windowsWidth < 992">
          <AppSidebar />
        </b-col>
        <b-col :cols="12">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[{ text: 'Inicio', href: 'project/' }]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="8" class="order-2 order-lg-1"
              ><h1 class="float-left">Proyectos</h1></b-col
            >
          </b-row>

          <b-row class="mt-5">
            <b-col><h2 class="text-left left-line">Filtro</h2></b-col>
          </b-row>

          <b-form inline class="row">
            <b-col cols="12" lg="9">
              <b-form-input
                v-model="filterValue2"
                v-on:keyup.enter="search"
                placeholder="Buscar obra o arquitecto"
              ></b-form-input>
            </b-col>
            <b-col cols="12" lg="3">
              <div class="form-inline__actions">
                <b-link
                  class="uppercase-link align-middle"
                  @click="cleanFilter"
                >
                  Limpiar</b-link
                >
                <b-button @click="search" variant="primary" class="float-right"
                  >Filtrar</b-button
                >
              </div>
            </b-col>
          </b-form>

          <b-row class="mt-5">
            <b-col><h2 class="text-left left-line">Obras asignadas</h2></b-col>
          </b-row>

          <b-row
            ><b-col
              ><b-table
                hover
                :items="searchProjects"
                :fields="fields"
                :stacked="windowsWidth < 992"
                class="mt-2"
              >
                <template #cell(actions)="data">
                  <b-link
                    class="table__action-link"
                    :to="{
                      name: 'ProjectAccreditationDocuments',
                      params: {
                        project_slug: data.item.slug,
                      },
                    }"
                  >
                    <b-badge v-if="data.item.accredited" variant="success"
                      >Acreditación</b-badge
                    ></b-link
                  >

                  <b-link
                    class="table__action-link mr-2"
                    :to="{
                      name: 'ProjectAccreditationDocuments',
                      params: {
                        project_slug: data.item.slug,
                      },
                    }"
                  >
                    <b-badge v-if="!data.item.accredited" variant="warning"
                      >Acreditación</b-badge
                    ></b-link
                  >
                  <b-link
                    class="table__action-link mr-2"
                    :to="{
                      name: 'ProjectDocuments',
                      params: {
                        project_slug: data.item.slug,
                      },
                    }"
                    ><!--<svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path
                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                      />
                    </svg>-->
                    <b-badge variant="light">Mensuales</b-badge></b-link
                  >
                </template>
              </b-table></b-col
            ></b-row
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import { mapState } from "vuex";
import tablesServices from "@/services/tables.services";

export default {
  name: "Project",
  components: {
    AppSidebar,
  },
  data() {
    return {
      projects: [],
      filterValue: "",
      filterValue2: "",
      fields: [
        {
          key: "name",
          label: "Obra",
          sortable: true,
        },
        {
          key: "arquitect",
          label: "Arquitecto",
        },
        {
          key: "location",
          label: "Ubicación",
        },

        {
          key: "actions",
          label: "Ver documentos",
        },
      ],
    };
  },
  computed: {
    ...mapState("front", ["windowsWidth"]),
    searchProjects() {
      let result = this.projects;
      if (!this.filterValue) {
        return result;
      }

      const filterValue = this.filterValue.toLowerCase();

      const filter = (project) =>
        project.name.toLowerCase().includes(filterValue);

      return result.filter(filter);
    },
  },
  mounted() {
    tablesServices.getProjects().then((projects) => (this.projects = projects));
  },
  methods: {
    search() {
      this.filterValue = this.filterValue2;
    },
    cleanFilter() {
      this.filterValue = "";
      this.filterValue2 = "";
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";
</style>
