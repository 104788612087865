<template>
  <div class="assigned-contractors">
    <b-container fluid>
      <b-row>
        <b-col class="pl-0">
          <AppSidebar />
        </b-col>
        <b-col :cols="windowsWidth > 991 ? 9 : 12">
          <b-row v-if="project && contractor">
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[
                  { text: 'Inicio', to: { name: 'Dashboard' } },
                  {
                    text: project.name,
                    to: {
                      name: 'Project',
                      params: {
                        project_slug: project.slug,
                      },
                    },
                  },
                  {
                    text: contractor.name,
                  },
                ]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row v-if="project && contractor">
            <b-col cols="12" lg="8" class="order-2 order-lg-1"
              ><h1 class="float-left">
                {{ contractor.name }}
                <b-link
                  :to="{
                    name: 'AccreditationDocuments',
                    params: {
                      project_slug: project.slug,
                      contractor_slug: contractor.slug,
                    },
                  }"
                  class="title-link"
                  ><check-circle-icon v-if="accredited"></check-circle-icon>
                  <span class="text">Acreditación inicial</span></b-link
                >
              </h1>
              <p
                v-if="
                  contractor &&
                  ((contractor.contact_name &&
                    contractor.contact_name.length &&
                    contractor.contact_name.length > 0) ||
                    (contractor.contact_phone &&
                      contractor.contact_phone.length &&
                      contractor.contact_phone.length > 0))
                "
                class="project-title-meta"
              >
                contacto: {{ contractor.contact_name }}
                {{ contractor.contact_phone }}
              </p>
              <p v-else class="project-title-meta">Sin contacto asignado</p>
            </b-col>
            <b-col cols="12" lg="4" class="order-1 order-lg-2"
              ><b-button
                v-if="canUpload"
                variant="primary"
                class="float-right"
                :to="{
                  name: 'UploadDocumentFormM3',
                  params: {
                    project_slug: project.slug,
                    contractor_slug: contractor.slug,
                  },
                }"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-plus-lg mr-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  /></svg
                >Subir Documento</b-button
              >
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col><h2 class="text-left left-line">Filtros</h2></b-col>
          </b-row>
          <b-form inline class="row">
            <b-col cols="12" lg="3">
              <b-form-input
                @keydown="searchText()"
                v-model="filters.keywords"
                placeholder="Buscar..."
              ></b-form-input>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-select
                @change="search()"
                v-model="filters.period"
                :options="filterPeriod"
              ></b-form-select>
            </b-col>

            <b-col cols="12" lg="3">
              <b-form-select
                @change="search()"
                v-model="filters.status"
                :options="filterStatus"
              ></b-form-select>
            </b-col>
            <b-col cols="12" lg="3">
              <div class="form-inline__actions">
                <b-link class="uppercase-link align-middle">Limpiar</b-link>
              </div>
            </b-col>
          </b-form>

          <b-row class="mt-5">
            <b-col><h2 class="text-left left-line">Documentos</h2></b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table
                hover
                :items="documents"
                :fields="fields"
                :stacked="windowsWidth < 992"
                :busy="loading"
                class="mt-2"
                show-empty
              >
                <template #table-busy>
                  <div class="text-center my-4">
                    <b-spinner class="align-middle" medium></b-spinner>
                  </div> </template
                ><template #empty=""> No existen documentos </template
                ><template #cell(date)="data">
                  <span class="second-text">{{
                    data.item.date | dateToMMMMYYYY
                  }}</span>
                </template>
                <template #cell(document_type)="data"
                  ><p class="document-type-paragraph">
                    <span
                      class="truncate"
                      v-b-tooltip.hover
                      :title="data.item.document_type"
                      >{{ data.item.document_type }}</span
                    >
                    <br />
                    <b-badge v-if="data.item.stage.code === 0">
                      Acreditación</b-badge
                    >
                  </p></template
                >
                <template #cell(files)="data">
                  <span class="first-text">
                    <b-link
                      :to="{
                        name: 'EditDocumentFormM3',
                        params: {
                          project_slug: $route.params.project_slug,
                          contractor_slug: $route.params.contractor_slug,
                          documentId: data.item.id,
                        },
                      }"
                      >{{ getFileQuantity(data.item.files) }}</b-link
                    >
                  </span>
                  <span class="second-text"
                    >{{
                      getFilesTotalSize(data.item.files)
                        | number("0,0", { thousandsSeparator: "." })
                    }}
                    KB</span
                  >
                </template>
                <template #cell(description)="data"
                  ><p class="description-paragraph">
                    {{ data.item.description }}
                  </p></template
                >
                <template #cell(status)="data">
                  <b-button
                    v-b-tooltip.hover
                    class="status-btn remove-btn-styling"
                    :title="$options.filters.translate_status(data.item.status)"
                    ><minus-circle-icon
                      v-if="data.item.status.code === 'rejected'"
                    ></minus-circle-icon>
                    <check-circle-icon
                      v-else-if="data.item.status.code === 'approved'"
                    ></check-circle-icon
                    ><dots-horizontal-circle-icon
                      v-else-if="data.item.status.code === 'does_not_apply'"
                    ></dots-horizontal-circle-icon
                    ><svg
                      v-else-if="data.item.status.code === 'new'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-patch-exclamation-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                      /></svg
                  ></b-button>
                </template>
                <template #cell(actions)="data">
                  <div class="actions">
                    <CommentIconComponent
                      v-if="canComment"
                      :number-of-comments="data.item.comments.length"
                      :document-id="data.item.id"
                      v-on:loadComments="loadComments"
                    ></CommentIconComponent>
                    <b-button
                      href="#"
                      v-b-tooltip.hover
                      v-if="canEdit"
                      class="remove-btn-styling"
                      title="Editar"
                      :class="{
                        disabled: data.item.status === 'does_not_apply',
                      }"
                      :to="{
                        name: 'EditDocumentFormM3',
                        params: {
                          project_slug: $route.params.project_slug,
                          contractor_slug: $route.params.contractor_slug,
                          documentId: data.item.id,
                        },
                      }"
                    >
                      <pencil-outline-icon></pencil-outline-icon>
                    </b-button>

                    <b-button
                      v-if="canDelete"
                      href="#"
                      v-b-tooltip.hover
                      @click="removeDocument(data.item)"
                      class="remove-btn-styling"
                      title="Eliminar"
                      :class="{
                        disabled: data.item.status === 'does_not_apply',
                      }"
                    >
                      <delete-outline-icon></delete-outline-icon>
                    </b-button>
                  </div>
                </template>
                <template #cell(three_dots)="data"
                  ><b-dropdown
                    v-b-tooltip.hover
                    v-if="canApprove"
                    class="remove-btn-styling"
                    title="Más opciones"
                    right
                  >
                    <template #button-content
                      ><dots-vertical-icon></dots-vertical-icon
                    ></template>
                    <b-dropdown-item
                      v-if="data.item.status.code !== 'approved'"
                      @click="changeStatusHandler(data.item, 'approved')"
                    >
                      Aprobar documentos</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="data.item.status.code !== 'rejected'"
                      @click="changeStatusHandler(data.item, 'rejected')"
                    >
                      Rechazar documento</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="data.item.status.code !== 'does_not_apply'"
                      @click="changeStatusHandler(data.item, 'does_not_apply')"
                    >
                      No aplica</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <CommentModal :documentId="documentId" @refreshComments="refreshComments" />
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import CheckCircleIcon from "vue-material-design-icons/CheckCircle.vue";
import CommentIconComponent from "@/components/CommentIconComponent.vue";
import CommentModal from "@/components/CommentModal.vue";
import DeleteOutlineIcon from "vue-material-design-icons/DeleteOutline.vue";
import DotsHorizontalCircleIcon from "vue-material-design-icons/DotsHorizontalCircle.vue";
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical.vue";
import MinusCircleIcon from "vue-material-design-icons/MinusCircle.vue";
import PencilOutlineIcon from "vue-material-design-icons/PencilOutline.vue";
import { mapState, mapMutations } from "vuex";
import defaultConfig from "@/config/defaultConfig";
import tablesServices from "@/services/tables.services";
import documentsServices from "@/services/documents.services";
import moment from "moment";

export default {
  name: "AssignedContractors",
  components: {
    AppSidebar,
    CheckCircleIcon,
    CommentIconComponent,
    CommentModal,
    DeleteOutlineIcon,

    PencilOutlineIcon,
    DotsHorizontalCircleIcon,
    DotsVerticalIcon,
    MinusCircleIcon,
  },
  data() {
    return {
      timer: null,
      filters: {
        keywords: "",
        period: null,
        status: null,
        stage: 1,
      },

      filterPeriod: [{ text: "Todos los periodos", value: null }],
      filterStatus: [
        { text: "Todos los estados", value: null },
        { text: "Aprobados", value: "approved" },
        { text: "Rechazados", value: "rejected" },
        { text: "Pendientes", value: "pending" },
      ],
      fields: [
        {
          key: "date",
          label: "Fecha",
        },
        {
          key: "document_type",
          label: "Tipo de documento",
        },
        {
          key: "files",
          label: "Archivos",
        },
        {
          key: "description",
          label: "Descripción",
        },
        {
          key: "status",
          label: "Estado",
        },
        {
          key: "actions",
          label: "Acciones",
        },
        {
          key: "three_dots",
          label: "",
        },
      ],
      documentId: null,
      loading: false,
      project: null,
      contractor: null,
      documents: [],
      accredited: false,
    };
  },
  computed: {
    ...mapState("front", ["windowsWidth"]),
    ...mapState("auth", ["user"]),
    ...mapState("documents", ["currentFilters"]),
    canDelete() {
      return this.user.role == 0 || this.user.role == 1;
    },
    canUpload() {
      return this.user.role == 0 || this.user.role == 1 || this.user.role == 3;
    },
    canApprove() {
      return this.user.role == 0 || this.user.role == 1 || this.user.role == 3;
    },
    canEdit() {
      return this.user.role == 0 || this.user.role == 1 || this.user.role == 3;
    },
    canComment() {
      return this.user.role == 0 || this.user.role == 1 || this.user.role == 3;
    },
  },
  mounted() {
    let months_text = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), 1);

    let months = Array(7)
      .fill(0)
      .map((_, i) => {
        var date = moment(today).subtract(i, "M").toDate();

        return {
          text: `${months_text[date.getMonth()]} ${date.getFullYear()}`,
          value: parseInt(
            `${date.getFullYear()}${new String(date.getMonth() + 1).padStart(
              2,
              "0"
            )}`,
            10
          ),
        };
      });

    this.filterPeriod = this.filterPeriod.concat(months);

    if (this.currentFilters.period != null) {
      this.filters.period = this.currentFilters.period;
    } else {
      this.filters.period = months[0].value;
    }

    tablesServices
      .getContractor(this.$route.params.contractor_slug)
      .then((contractor) => (this.contractor = contractor));

    tablesServices
      .getProject(this.$route.params.project_slug)
      .then((project) => (this.project = project));

    documentsServices
      .getAccreditationStatus({
        projectSlug: this.$route.params.project_slug,
        contractorSlug: this.$route.params.contractor_slug,
      })
      .then((accredited) => (this.accredited = accredited));
    this.search();
  },
  methods: {
    ...mapMutations("documents", ["setFilter"]),
    searchText() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.search();
      }, 1000);
    },
    getFileQuantity(documentFiles) {
      const length = documentFiles.length;
      return length === 1 ? "1 archivo" : `${length} archivos`;
    },
    getFilesTotalSize(documentFiles) {
      return documentFiles
        .map((item) => item.size)
        .reduce((acc, curr) => acc + curr, 0);
    },
    search() {
      this.setFilter({ filters: { period: this.filters.period } });
      tablesServices
        .searchDocuments({
          projectSlug: this.$route.params.project_slug,
          companySlug: this.$route.params.contractor_slug,
          filters: this.filters,
        })
        .then((data) => (this.documents = data.results));
    },
    loadComments(documentId) {
      this.documentId = documentId;
      this.$bvModal.show("comment-modal");
    },
    refreshComments() {
      tablesServices.getDocuments({
        projectSlug: this.$route.params.project_slug,
        filters: this.filters,
      });
    },
    removeDocument(document) {
      this.$bvModal
        .msgBoxConfirm(
          `¿seguro que deseas eliminar el documento "${document.description}"`,
          {
            okTitle: "Confirmar",
            cancelTitle: "Cancelar",
          }
        )
        .then((value) => {
          if (value) {
            documentsServices
              .deleteDocument({
                projectSlug: this.$route.params.project_slug,
                documentId: document.id,
              })
              .then(() => {
                this.search();

                this.$bvToast.toast("El documento fue eliminado", {
                  title: "Acreditación Inicial",
                  toaster: "b-toaster-top-center",
                  solid: true,
                  appendToast: false,
                  variant: "success",
                });
              });
          }
        });
    },
    download_url(document) {
      return `${defaultConfig.urlApi}/document_download/${document.id}/`;
    },
    changeStatusHandler(document, status) {
      documentsServices
        .changeDocumentStatus({
          documentId: document.id,
          status: status,
          projectSlug: this.$route.params.project_slug,
        })
        .then(() => {
          document.status.code = status;
          this.$bvToast.toast("El documento fue aprobado", {
            title: "Acreditación Inicial",
            toaster: "b-toaster-top-center",
            solid: true,
            appendToast: false,
            variant: "success",
          });
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
  display: inline-block;
  cursor: pointer;
}

.truncate::first-letter {
  text-transform: capitalize !important;
}

.table td {
  padding: 0.55rem 0.55rem 0.3rem 0.55rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;

  p {
    margin-bottom: 2px;
  }
}

.assigned-contractors {
  .table {
    .document-type-paragraph {
      text-transform: lowercase !important;

      @media (min-width: $lg-min) {
        max-width: 200px;
      }
    }

    .description-paragraph {
      @media (min-width: $lg-min) {
        max-width: 270px;
      }
    }
    .status-btn {
      svg {
        height: 21px;
      }
      .check-circle-icon {
        color: $success-color;
      }
      .dots-horizontal-circle-icon {
        color: #cccccc;
      }
      .minus-circle-icon {
        color: $error-color;
      }
      .bi-patch-exclamation-fill {
        color: $warning-color;
        width: 23px;
      }
    }
    .actions {
      .comment-icon-component {
        margin-right: 20px;
      }
      a {
        color: $primary-color;
        &:first-of-type {
          margin-right: 20px;
        }
        &.disabled {
          color: #c8c8c8;
        }
      }
      .remove-btn-styling {
        &:hover {
          color: $primary-color;
        }
      }
    }
    .dropdown {
      &.show > .btn-secondary.dropdown-toggle {
        color: $light-grey;
        &:focus {
          box-shadow: none;
        }
      }
      > .dropdown-toggle {
        color: $light-grey;
        &.btn-secondary:not(:disabled):not(.disabled):active {
          color: $light-grey;
        }
        &:active,
        &:focus,
        &:hover {
          color: $light-grey;
          box-shadow: none;
        }
        + .dropdown-menu {
          border: 1px solid #dea9aa;
          border-radius: 0;
          padding: 0;
          box-shadow: 0px 2px 7px rgba(67, 63, 63, 0.15);
          li a {
            padding: 10px 1.5rem;
            color: $primary-color;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
    &.b-table.b-table-stacked > tbody > tr td[data-label="Estado"] {
      border-bottom: 1px solid #dee2e6;
    }
    &.b-table.b-table-stacked > tbody > tr[role="row"] {
      @media (max-width: $lg) {
        clear: both;
      }
      > td[data-label="Acciones"] {
        @media (max-width: $lg) {
          display: flex;
          border-top: none;
          float: right;
          order: 1;
          + td {
            display: flex;
            border: none;
            float: right;
            order: 2;
          }
        }
      }
    }
  }
}
</style>
