<template>
  <div class="app-header">
    <b-navbar toggleable="lg" type="light">
      <b-container v-if="isContractorRoute">
        <b-navbar-brand href="#">
          <img
            alt="Consultora Logo"
            src="../assets/m3-constructora-logo.png"
            width="75"
          />
          <span>Gestor de documentos</span>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-button v-b-toggle.mobile-sidebar class="sidebar-navbar-toggler">
          <span class="navbar-toggler-icon" style="color: #661819"></span
        ></b-button>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-text>{{ userFullName }}</b-nav-text>
            <b-nav-text class="ml-2">|</b-nav-text>
            <b-nav-item @click="logout" class="logout"
              >Cerrar sesión</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-container>

      <template v-else>
        <b-navbar-brand href="#">
          <img
            alt="Consultora Logo"
            src="../assets/m3-constructora-logo.png"
            width="75"
          />
          <span>Gestor de documentos</span>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-button
          v-if="!isLoginRoute"
          v-b-toggle.mobile-sidebar
          class="sidebar-navbar-toggler"
        >
          <span class="navbar-toggler-icon" style="color: #661819"></span
        ></b-button>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-if="!isLoginRoute"  class="ml-auto">
            <b-nav-text>{{ userFullName }}</b-nav-text>
            <b-nav-text class="ml-2">|</b-nav-text>
            <b-nav-item @click="logout" class="logout"
              >Cerrar sesión</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </template>
    </b-navbar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "AppHeader",
  computed: {
    ...mapGetters("auth", ["userFullName"]),
    ...mapState("auth", ["user"]),
    isContractorRoute() {
      return this.$route.matched.some(
        ({ name }) =>
          name === "ContractorHome" ||
          name === "InitialAccreditationForm" ||
          name === "AssignedProjects" ||
          name === "ProjectDocuments" ||
          name === "UploadDocumentForm" ||
          name === "EditDocumentForm"
      );
    },
    isLoginRoute() {
      return this.$route.matched.some(({ name }) => name === "Login");
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.app-header {
  background-color: white;
  .navbar {
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
    .navbar-brand {
      padding-top: 5px;
      padding-bottom: 5px;
      color: #535458;
      img {
        padding-right: 13px;
        @media (max-width: $lg) {
          width: 66.42px;
        }
      }
      span {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        position: relative;
        margin-left: 13px;
        @media (max-width: $lg) {
          font-size: 15.941px;
          line-height: 19px;
        }
        &:before {
          content: "";
          height: 35px;
          width: 1px;
          background-color: #999999;
          display: inline-block;
          position: absolute;
          left: -13px;
          @media (max-width: $lg) {
            height: 31px;
            top: -7px;
          }
        }
      }
    }
    .logout {
      a {
        color: $primary-color;
        text-decoration: underline;
      }
    }
    .navbar-toggler {
      display: none;
    }
    .sidebar-navbar-toggler.btn-secondary {
      @media (min-width: $lg-min) {
        display: none;
      }
      background-color: transparent;
      border-color: transparent;
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(102,24,25, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
      &:active {
        background-color: transparent;
        border-color: transparent;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
</style>
