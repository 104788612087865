import parseFileParameters from "../utils/parseFileParameters";
import APIRequest from "./api.services";

export function loadPeriods(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      `/api/docs/period/`,
      parameter,
      ({ data }) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getDocument(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      `/api/docs/project/${parameter.projectSlug}/document/${parameter.documentId}/`,
      parameter,
      ({ data }) => {
        resolve(data);
      },
      (error) => reject(error)
    );
  });
}

export function getDocumentByPeriod(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      `/api/docs/project/${parameter.projectSlug}/document/${parameter.documentId}/${parameter.period}/`,
      parameter,
      ({ data }) => {
        resolve(data);
      },
      (error) => reject(error)
    );
  });
}

export function deleteDocument(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.destroy(
      `/api/docs/project/${parameter.projectSlug}/document/${parameter.documentId}/`,
      {},
      ({ data }) => resolve(data),
      (error) => reject(error)
    );
  });
}

export function changeDocumentStatus(parameters) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      `/api/docs/${parameters.projectSlug}/document/${parameters.documentId}/set_status/${parameters.status}/`,
      parameters,
      ({ data }) => resolve(data),
      (error) => reject(error)
    );
  });
}

export function uploadDocument(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.post(
      "/api/docs/upload_document/",
      parameter,
      ({ data }) => {
        resolve(data);
      },
      (error) => reject(error)
    );
  });
}
export function updateDocument(parameters) {
  return new Promise((resolve, reject) => {
    APIRequest.post(
      `/api/docs/project/${parameters.projectSlug}/document/${parameters.document.id}/`,
      parameters,
      ({ data }) => resolve(data),
      (error) => reject(error)
    );
  });
}

export function uploadDocFile(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.post(
      "/api/docs/upload_document_file/",
      parseFileParameters(parameter),
      ({ data }) => resolve(data),
      (error) => reject(error),
      null,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  });
}

function convertDocumentTypesReponse(data) {
  return data.map((documentType) => {
    const newDocumentType = {
      ...documentType,
      description:
        documentType.document && documentType.document.description
          ? documentType.document.description
          : "",
      id:
        documentType.document && documentType.document.id
          ? documentType.document.id
          : null,
      document_type: documentType.id,
      files:
        documentType.document && documentType.document.files
          ? documentType.document.files
          : [],
      binaryFiles: [],
    };
    return newDocumentType;
  });
}

export function getAccreditationDocuments(parameter) {
  let url = `/api/docs/document_types/${parameter.project_slug}/initial/`;

  if (parameter.company_slug !== undefined) {
    url = `/api/docs/document_types/${parameter.project_slug}/${parameter.company_slug}/initial/`;
  }
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      url,
      parameter,
      function ({ data }) {
        // Esto debería ser arreglado en el servicio, por ahora
        // convertimos la respuesta a un objeto que mezcla tipos documentos y documentos
        resolve(convertDocumentTypesReponse(data));
      },
      (error) => reject(error)
    );
  });
}

export function getDocumentTypes(parameter) {
  let url = `/api/docs/document_types/${parameter.project_slug}/${parameter.company_slug}/${parameter.stage}/${parameter.period}/`;

  if (parameter.company_slug === undefined) {
    url = `/api/docs/document_types/${parameter.project_slug}/${parameter.stage}/${parameter.period}/`;
  }

  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      url,
      {},
      function ({ data }) {
        // Esto debería ser arreglado en el servicio, por ahora
        // convertimos la respuesta a un objeto que mezcla tipos documentos y documentos
        resolve(convertDocumentTypesReponse(data));
      },
      (error) => reject(error)
    );
  });
}

export function getContractorDocumentTypes(parameter) {
  let url = `/api/docs/contractor_document_types/${parameter.project_slug}/${parameter.stage}/${parameter.period}/`;

  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      url,
      {},
      function ({ data }) {
        // Esto debería ser arreglado en el servicio, por ahora
        // convertimos la respuesta a un objeto que mezcla tipos documentos y documentos
        resolve(convertDocumentTypesReponse(data));
      },
      (error) => reject(error)
    );
  });
}

export function getAccreditationStatus(parameters) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      `/api/docs/accreditation_status/${parameters.projectSlug}/${parameters.contractorSlug}/`,
      {},
      ({ data }) => {
        resolve(data.accredited);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  getAccreditationDocuments,
  getAccreditationStatus,
  getDocumentTypes,
  getContractorDocumentTypes,
  getDocument,
  getDocumentByPeriod,
  loadPeriods,
  uploadDocument,
  updateDocument,
  uploadDocFile,
  changeDocumentStatus,
  deleteDocument,
};
