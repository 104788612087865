<template>
  <div class="login">
    <!-- login inicial -->
    <LoginForm v-if="showLoginForm" />

    <!-- recuperación de contraseña -->
    <PasswordRecoveryForm v-if="showPasswordRecoveryForm" />

    <!-- cambio de contraseña -->
    <ChangePasswordForm v-if="showChangePasswordForm" />
  </div>
</template>
<script>
import LoginForm from "@/components/Login/LoginForm.vue";
import PasswordRecoveryForm from "@/components/Login/PasswordRecoveryForm.vue";
import ChangePasswordForm from "@/components/Login/ChangePasswordForm.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Login",
  components: {
    LoginForm,
    PasswordRecoveryForm,
    ChangePasswordForm,
  },
  computed: {
    ...mapState("auth", [
      "showChangePasswordForm",
      "showLoginForm",
      "showPasswordRecoveryForm",
      "authed",
    ]),
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const uid = urlParams.get("uid");

    if (
      token &&
      token.length &&
      token.length > 0 &&
      uid &&
      uid.length &&
      uid.length > 0
    ) {
      this.toggleLoginFormPasswordRecoveryFormChangePasswordForm({
        showChangePasswordForm: true,
        showLoginForm: false,
        showPasswordRecoveryForm: false,
      });
    } else {
      this.toggleLoginFormPasswordRecoveryFormChangePasswordForm({
        showChangePasswordForm: false,
        showLoginForm: true,
        showPasswordRecoveryForm: false,
      });
    }
  },
  mounted() {
    this.setLoginLoading({ loginLoading: false });
  },
  watch: {
    authed: function (newValue) {
      if (newValue === false) {
        this.$bvToast.toast(
          "No se pudo autentificaer, revise el usuario y/o contraseña",
          {
            title: "Autentificación",
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          }
        );
      }
    },
  },
  methods: {
    ...mapActions("auth", [
      "toggleLoginFormPasswordRecoveryFormChangePasswordForm",
    ]),
    ...mapMutations("auth", ["setLoginLoading"]),
  },
};
</script>

<style lang="scss">
@import "@/styles/_variables";

.login {
  background-image: url(~@/assets/bg.png);
  background-size: cover;
  @media (max-width: $lg) {
    height: calc(100vh - 45.49px);
  }
  @media (min-width: $lg-min) {
    min-height: calc(100vh - 51.33px);
  }
  &__card {
    &.card {
      width: calc(100% - 20px);
      top: 115.49px;
      @media (min-width: $lg-min) {
        width: 520px;
        height: 470px;
        top: 143.33px;
      }
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      .card-body {
        position: relative;
        padding: 26px 26px 46px;
        @media (max-width: $lg) {
          padding: 30px 10px 40px;
        }
        .card-title {
          font-weight: bold;
          font-size: 35px;
          line-height: 43px;
          color: #333333;
          margin-bottom: 30px;
          @media (max-width: $lg) {
            font-size: 28px;
            line-height: 34px;
          }
        }
        form {
          .form-group {
            margin-bottom: 30px;
            @media (max-width: $lg) {
              margin-bottom: 22px;
            }
            label {
              font-weight: bold;
              color: $light-grey;
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 7px;
              &[label-for="input-password"] {
                width: 100%;
                a {
                  font-weight: normal;
                  font-size: 14px;
                  color: $primary-color;
                  text-decoration: underline;
                  float: right;
                }
              }
            }
            input {
              &.form-control::placeholder {
                color: #dea9aa;
                font-size: 16px;
                line-height: 20px;
                font-weight: normal;
                opacity: 1;
              }
              &.form-control:-ms-input-placeholder {
                color: #dea9aa;
                font-size: 16px;
                line-height: 20px;
                font-weight: normal;
              }
              &.form-control::-ms-input-placeholder {
                color: #dea9aa;
                font-size: 16px;
                line-height: 20px;
                font-weight: normal;
              }
            }
          }
          .custom-control.custom-switch {
            @media (max-width: $lg) {
              margin-top: 45px;
            }
            label {
              color: $dark-grey;
              font-size: 16px;
            }
          }
          .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $primary-color;
            border-color: $primary-color;
          }
          .btn.btn-primary {
            @media (max-width: $lg) {
              margin-top: 55px;
              width: 100%;
            }
            @media (min-width: $lg-min) {
              width: calc(100% - 46px);
              position: absolute;
              bottom: 36px;
            }
          }
        }
      }
    }
  }
  &__back {
    @media (min-width: $lg-min) {
      display: none;
    }
    display: block;
    font-weight: normal;
    font-size: 14px;
    color: $primary-color;
    text-decoration: underline;
    margin: 45px auto 0;
    text-align: center;
  }
}
</style>
