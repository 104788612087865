function createMixedDocumentArray(data) {
  const documents = data.reduce((previous, current) => {
    const currentDocument = {
      ...current,
      description:
        current.document && current.document.description
          ? current.document.description
          : "",
      id: current.document && current.document.id ? current.document.id : null,
      document_type: current.id,
      files:
        current.document && current.document.files
          ? current.document.files
          : [],
      binaryFiles: [],
    };

    previous.push(currentDocument);
    return previous;
  }, []);

  return documents;
}

function updateDocuments(documents, document, binaryFiles, newFiles) {
  const newUpdatedDocument = {
    ...document,
    binaryFiles: binaryFiles,
    files: newFiles.concat(document.files),
  };

  // Creamos un arreglo nuevo actualizando reemplazando el documento
  const updatedDocuments = documents.map((el) =>
    el.document_type === newUpdatedDocument.document_type
      ? newUpdatedDocument
      : el
  );

  return updatedDocuments;
}

export { createMixedDocumentArray, updateDocuments };
