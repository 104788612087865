import Vue from "vue";
import moment from "moment";

Vue.filter("translate_status", function (value) {
  return {
    /* eslint-disable prettier/prettier */
      "approved": "Aprobado",
      "does_not_apply": "No aplica",
      "new": "Nuevo",
      "pending": "Pendiente",
      "rejected": "Rechazado"
      /* eslint-enable prettier/prettier */
  }[value];
});

Vue.filter("remove_dash_and_uppercase", function (value) {
  if (!value) return "";
  value = value.replace(/-/g, " ").toLowerCase();
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});

Vue.filter("add_dash_and_remove_uppercase", function (value) {
  if (!value) return "";
  value = value.replace(/ /g, "-").toLowerCase();
  return value;
});

Vue.filter("date", function (dataStr, pattern = "DD/MM/YYYY") {
  if (!dataStr) return "-";
  return moment(dataStr).format(pattern);
});

Vue.filter("dateToMMMMYYYY", function (dataStr, pattern = "MMMM/YYYY") {
  if (!dataStr) return "-";
  const date = moment(dataStr).locale("es").format(pattern);
  const capitalized = date.charAt(0).toUpperCase() + date.slice(1);
  return capitalized;
});
