import documentsServices from "../services/documents.services";
import { createMixedDocumentArray } from "@/utils/documents";

const getDefaultState = () => {
  return {
    documentTypes: [],
    loadingUploadDocument: false,
    loadingUploadDocumentFile: false,
    periods: [],
    currentFilters: { period: null },
    accredited: false,
    document: null,
    selectedProjectDate: null,
    loadingInitialAccreditation: false,
  };
};

const documents = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    loadPeriods({ commit }) {
      commit("setLoadingUploadDocumentFile", {
        loadingUploadDocumentFile: true,
      });

      return new Promise((resolve, reject) => {
        documentsServices.loadPeriods(
          {},
          ({ data }) => {
            commit("setPeriods", { periods: data });
            resolve(data);
          },
          (err) => reject(err),
          () =>
            commit("setLoadingUploadDocumentFile", {
              loadingUploadDocumentFile: false,
            })
        );
      });
    },
    uploadDocumentFile({ commit, state }, params) {
      commit("setLoadingUploadDocumentFile", {
        loadingUploadDocumentFile: true,
      });

      return new Promise((resolve, reject) => {
        documentsServices.uploadDocFile(
          { files: params.files, projectId: params.projectId },
          ({ data }) => {
            // Buscar el documento en el store
            const updatedDocument = state.documents.find(
              (item) => item.document_type === params.document.document_type
            );

            const newUpdatedDocument = {
              ...updatedDocument,
              binaryFiles: params.files,
              files: data.concat(updatedDocument.files), // Juntamos lo que había con lo que he cargado
            };
            const updatedItems = state.documents.map((el) =>
              el.document_type === newUpdatedDocument.document_type
                ? newUpdatedDocument
                : el
            );
            commit("setDocuments", { documents: updatedItems });
            resolve();
          },
          (error) => reject(error),
          () =>
            commit("setLoadingUploadDocumentFile", {
              loadingUploadDocumentFile: false,
            })
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    uploadDocumentFileSingleResponse({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        documentsServices.uploadDocFile(
          params,
          ({ data }) => {
            state.document.files = data.concat(state.document.files);
            resolve(state.document);
          },
          (error) => reject(error)
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    changeDocumentStatus({ commit }, params) {
      return new Promise((resolve, reject) => {
        documentsServices.changeDocumentStatus(
          {
            project_slug: params.projectSlug,
            document_id: params.documentId,
            status: params.status,
          },
          ({ data }) => {
            commit("setAccredtiationStatus", { accredited: data.accredited });
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    getAccreditationStatus({ commit }, params) {
      return new Promise((resolve, reject) => {
        documentsServices.getAccreditationStatus(
          {
            project_slug: params.projectSlug,
            contractor_slug: params.contractorSlug,
          },
          ({ data }) => {
            commit("setAccredtiationStatus", { accredited: data.accredited });
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    uploadDocument({ commit }, { params, vm }) {
      const documents = params.documents.reduce((acc, curr) => {
        if (curr) {
          const formattedFiles = curr.files.reduce((acc, curr) => {
            if (curr) {
              const file = { id: curr.id };
              acc.push(file);
            }
            return acc;
          }, []);
          const currDocument = {
            document_type: curr.document_type,
            id: curr.id,
            description: curr.description,
            files:
              curr.files && curr.files.length && curr.files.length > 0
                ? formattedFiles
                : [],
          };
          acc.push(currDocument);
        }
        return acc;
      }, []);

      const parameters = {
        project_id: params.project_id,
        company_id: params.company_id,
        period: params.period,
        stage: params.stage,
        documents,
      };

      commit("setLoadingUploadDocument", {
        loadingUploadDocument: true,
      });

      return new Promise((resolve, reject) => {
        documentsServices.uploadDocument(
          parameters,
          ({ data }) => {
            resolve(data);
          },
          () => {
            reject();
            vm.$bvToast.toast("Hubo un problema subiendo los documentos", {
              title: "¡Lo sentimos!",
            });
          },
          () =>
            commit("setLoadingUploadDocument", {
              loadingUploadDocument: false,
            })
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    updateDocument({ commit }, parameters) {
      return new Promise((resolve, reject) => {
        documentsServices.updateDocument(
          parameters,
          ({ data }) => {
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    getAccreditationDocuments({ commit }, project_slug) {
      commit("setLoadingInitialAccreditation", {
        loadingInitialAccreditation: true,
      });

      documentsServices.getAccreditationDocuments(
        { project_slug },
        ({ data }) => {
          const documents = createMixedDocumentArray(data);
          commit("setDocuments", { documents: documents });
        },
        () => {},
        () =>
          commit("setLoadingInitialAccreditation", {
            loadingInitialAccreditation: false,
          })
      );
    },
    // eslint-disable-next-line no-unused-vars
    getDocument({ commit }, { project_slug, documentId }) {
      return new Promise((resolve, reject) => {
        documentsServices.getDocument(
          { project_slug, documentId },
          ({ data }) => {
            commit("setDocument", { document: data });
            resolve(data);
          },
          (error) => reject(error)
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    deleteDocument({ commit }, { projectSlug, documentId }) {
      return new Promise((resolve, reject) => {
        documentsServices.deleteDocument(
          { projectSlug, documentId },
          ({ data }) => {
            commit("setAccredtiationStatus", { accredited: data.accredited });
            resolve(data);
          },
          (error) => reject(error)
        );
      });
    },
  },

  mutations: {
    setDocuments(state, { documents }) {
      state.documents = documents;
    },
    setFilter(state, { filters }) {
      state.currentFilters = filters;
    },
    resetFilter(state) {
      state.currentFilters = { period: null };
    },
    setDocumentTypes(state, { documentTypes }) {
      state.documentTypes = documentTypes;
    },
    setLoadingUploadDocument(state, { loadingUploadDocument }) {
      state.loadingUploadDocument = loadingUploadDocument;
    },
    setLoadingUploadDocumentFile(state, { loadingUploadDocumentFile }) {
      state.loadingUploadDocumentFile = loadingUploadDocumentFile;
    },
    setPeriods(state, { periods }) {
      state.periods = periods;
    },
    setSelectedProjectDate(state, { selectedProjectDate }) {
      state.selectedProjectDate = selectedProjectDate;
    },
    updateSelectedProjectDate(state, selectedProjectDate) {
      state.selectedProjectDate = selectedProjectDate;
    },
    setLoadingInitialAccreditation(state, { loadingInitialAccreditation }) {
      state.loadingInitialAccreditation = loadingInitialAccreditation;
    },
    setDocument(state, { document }) {
      state.document = document;
    },
    setAccredtiationStatus(state, { accredited }) {
      state.accredited = accredited;
    },
  },
};

export default documents;
