function parseFileParameters(params) {
  const fd = new FormData();
  fd.append("project_id", params.projectId);
  for (const i in params.files) {
    fd.append("file", params.files[i]);
  }
  return fd;
}

export default parseFileParameters;
