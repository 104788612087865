<template>
  <div class="project-documents">
    <b-container>
      <b-row>
        <b-col class="pl-0" v-if="windowsWidth < 992">
          <AppSidebar />
        </b-col>
        <b-col :cols="12">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[
                  {
                    text: 'Inicio',
                    to: {
                      name: 'AssignedProjects',
                    },
                  },
                  {
                    text: 'Documentos',
                  },
                ]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row v-if="project">
            <b-col cols="12" lg="8" class="order-2 order-lg-1"
              ><h1 class="float-left">
                {{ project.name }}
              </h1></b-col
            ><b-col cols="12" class="order-2 order-lg-3"
              ><b-link class="title-link float-left" v-if="accredited"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                <span class="text">Acreditación inicial</span></b-link
              ></b-col
            >
            <b-col cols="12" lg="4" class="order-1 order-lg-2"
              ><b-button
                variant="primary"
                class="float-right"
                :to="{
                  name: 'UploadDocumentForm',
                  params: {
                    project_slug: project.slug,
                  },
                }"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-plus-lg mr-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  /></svg
                >Subir Documento</b-button
              >
              <!--<b-button
                v-if="!accredited"
                variant="primary"
                class="float-right"
                :to="{
                  name: 'InitialAccreditationForm',
                  params: {
                    project_slug: project.slug,
                  },
                }"
                >Acreditar</b-button
              >-->
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col><h2 class="text-left left-line">Filtros</h2></b-col>
          </b-row>

          <b-form inline class="row">
            <b-col cols="12" lg="3">
              <b-form-input
                v-model="filters.keywords"
                placeholder="Buscar obra o arquitecto"
              ></b-form-input>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-select
                v-model="filters.period"
                :options="filterPeriod"
              ></b-form-select>
            </b-col>

            <b-col cols="12" lg="3">
              <b-form-select
                v-model="filters.status"
                :options="filterStatus"
              ></b-form-select>
            </b-col>
            <b-col cols="12" lg="3">
              <div class="form-inline__actions">
                <b-link class="uppercase-link align-middle">Limpiar</b-link>
                <b-button variant="primary" class="float-right" @click="search"
                  >Filtrar</b-button
                >
              </div>
            </b-col>
          </b-form>

          <b-row class="mt-5">
            <b-col><h2 class="text-left left-line">Obras asignadas</h2></b-col>
          </b-row>

          <b-row
            ><b-col
              ><b-table
                hover
                :items="documents"
                :fields="fields"
                :stacked="windowsWidth < 992"
                class="mt-2"
                show-empty
                ><template #empty=""> No existen documentos </template
                ><template #cell(date)="data">
                  <span class="first-text">{{ data.item.date | date }}</span>
                  <span class="second-text">{{
                    data.item.date | dateToMMMMYYYY
                  }}</span>
                </template>
                <template #cell(document_type)="data"
                  ><p class="document-type-paragraph">
                    {{ data.item.document_type }}
                  </p></template
                >
                <template #cell(files)="data">
                  <span class="first-text">
                    <b-link
                      :to="{
                        name: 'ViewDocumentForm',
                        params: {
                          documentId: data.item.id,
                          project_slug: $route.params.project_slug,
                        },
                      }"
                      >{{ getFileQuantity(data.item.files) }}</b-link
                    >
                  </span>
                  <span class="second-text"
                    >{{ getFilesTotalSize(data.item.files) }} KB</span
                  >
                </template>
                <template #cell(description)="data"
                  ><p class="description-paragraph">
                    {{ data.item.description }}
                  </p></template
                >
                <template #cell(status)="data">
                  <b-button
                    v-b-tooltip.hover
                    class="status-btn remove-btn-styling"
                    :title="$options.filters.translate_status(data.item.status)"
                    ><minus-circle-icon
                      v-if="data.item.status.code === 'rejected'"
                    ></minus-circle-icon>
                    <check-circle-icon
                      v-else-if="data.item.status.code === 'approved'"
                    ></check-circle-icon
                    ><dots-horizontal-circle-icon
                      v-else-if="data.item.status.code === 'does_not_apply'"
                    ></dots-horizontal-circle-icon
                    ><svg
                      v-else-if="data.item.status.code === 'new'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-patch-exclamation-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                      /></svg
                  ></b-button>
                </template>
                <template #cell(actions)="data">
                  <div class="actions">
                    <CommentIconComponent
                      :number-of-comments="data.item.comments.length"
                      :document-id="data.item.id"
                      v-on:loadComments="loadComments"
                    ></CommentIconComponent>
                    <b-button
                      href="#"
                      v-b-tooltip.hover
                      class="remove-btn-styling"
                      title="Editar"
                      :class="{
                        disabled: data.item.status.code !== 'new',
                      }"
                      :to="{
                        name: 'EditDocumentForm',
                        params: {
                          documentId: data.item.id,
                          project_slug: $route.params.project_slug,
                        },
                      }"
                    >
                      <pencil-outline-icon></pencil-outline-icon>
                    </b-button>
                    <b-button
                      @click="removeDocument(data.item)"
                      href="#"
                      v-b-tooltip.hover
                      class="remove-btn-styling"
                      title="Eliminar"
                      :class="{
                        disabled: data.item.status.code !== 'new',
                      }"
                    >
                      <delete-outline-icon></delete-outline-icon>
                    </b-button>
                  </div>
                </template> </b-table></b-col
          ></b-row>
        </b-col>
      </b-row>
    </b-container>
    <CommentModal :documentId="documentId" @refreshComments="refreshComments" />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import AppSidebar from "@/components/AppSidebar.vue";
import CommentModal from "@/components/CommentModal.vue";
import CheckCircleIcon from "vue-material-design-icons/CheckCircle.vue";
import CommentIconComponent from "@/components/CommentIconComponent.vue";
import DeleteOutlineIcon from "vue-material-design-icons/DeleteOutline.vue";
import DotsHorizontalCircleIcon from "vue-material-design-icons/DotsHorizontalCircle.vue";

import MinusCircleIcon from "vue-material-design-icons/MinusCircle.vue";
import PencilOutlineIcon from "vue-material-design-icons/PencilOutline.vue";
import defaultConfig from "@/config/defaultConfig";
import documentsServices from "@/services/documents.services";
import tablesServices from "@/services/tables.services";

export default {
  name: "ProjectDocuments",
  components: {
    AppSidebar,
    CheckCircleIcon,
    CommentIconComponent,
    CommentModal,
    DeleteOutlineIcon,
    DotsHorizontalCircleIcon,

    MinusCircleIcon,
    PencilOutlineIcon,
  },
  data() {
    return {
      documentId: null,
      project: null,
      documents: [],
      accredited: false,
      filters: {
        keywords: "",
        period: null,
        status: null,
        stage: 1,
      },
      filterPeriod: [
        { text: "Todos los periodos", value: null },
        { text: "Mes actual", value: "current" },
        { text: "Mes anterior", value: "previous" },
      ],
      filterStatus: [
        { text: "Todos los estados", value: null },
        { text: "Aprobados", value: "approved" },
        { text: "Rechazados", value: "rejected" },
        { text: "Pendientes", value: "pending" },
      ],
      fields: [
        {
          key: "date",
          label: "Fecha",
        },
        {
          key: "document_type",
          label: "Tipo de documento",
        },
        {
          key: "files",
          label: "Archivos",
        },
        {
          key: "description",
          label: "Descripción",
        },
        {
          key: "status",
          label: "Estado",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],
    };
  },
  computed: {
    ...mapState("front", ["windowsWidth"]),
    ...mapState("auth", ["user"]),
  },
  mounted() {
    tablesServices
      .getProject(this.$route.params.project_slug)
      .then((project) => {
        this.project = project;
        this.search();
      });
    documentsServices
      .getAccreditationStatus({
        projectSlug: this.$route.params.project_slug,
        contractorSlug: this.user.company.slug,
      })
      .then((accredited) => (this.accredited = accredited));
  },
  methods: {
    ...mapActions("documents", ["getAccreditationStatus", "deleteDocument"]),
    getFileQuantity(documentFiles) {
      const length = documentFiles.length;
      return length === 1 ? "1 archivo" : `${length} archivos`;
    },

    getFilesTotalSize(documentFiles) {
      let size = documentFiles
        .map((item) => item.size)
        .reduce((acc, curr) => acc + curr, 0);
      return parseInt(size / 1000, 10);
    },
    removeDocument(document) {
      let description = document.description || document.document_type;
      this.$bvModal
        .msgBoxConfirm(
          `¿seguro que deseas eliminar el documento "${description}"`,
          {
            okTitle: "Confirmar",
            cancelTitle: "Cancelar",
          }
        )
        .then((value) => {
          if (value) {
            documentsServices
              .deleteDocument({
                projectSlug: this.$route.params.project_slug,
                documentId: document.id,
              })
              .then(() => {
                this.search();
              });
          }
        });
    },
    search() {
      tablesServices
        .getDocuments({
          projectSlug: this.$route.params.project_slug,
          filters: this.filters,
        })
        .then((documents) => {
          this.documents = documents.results;
        });
    },
    loadComments(documentId) {
      this.documentId = documentId;
      this.$bvModal.show("comment-modal");
    },
    refreshComments() {
      this.search();
    },
    download_url(document) {
      return `${defaultConfig.urlApi}/document_download/${document.id}/`;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.project-documents {
  .table {
    .document-type-paragraph {
      @media (min-width: $lg-min) {
        max-width: 200px;
      }
    }
    .description-paragraph {
      @media (min-width: $lg-min) {
        max-width: 270px;
      }
    }
    .status-btn {
      svg {
        height: 21px;
      }
      .check-circle-icon {
        color: $success-color;
      }
      .dots-horizontal-circle-icon {
        color: #cccccc;
      }
      .minus-circle-icon {
        color: $error-color;
      }
      .bi-patch-exclamation-fill {
        color: $warning-color;
        width: 23px;
      }
    }
    .actions {
      .comment-icon-component {
        margin-right: 20px;
      }
      a {
        color: $primary-color;
        &:first-of-type {
          margin-right: 20px;
        }
        &.disabled {
          color: #c8c8c8;
        }
      }
      .remove-btn-styling {
        &:hover {
          color: $primary-color;
        }
      }
    }
    .dropdown {
      &.show > .btn-secondary.dropdown-toggle {
        color: $light-grey;
        &:focus {
          box-shadow: none;
        }
      }
      > .dropdown-toggle {
        color: $light-grey;
        &.btn-secondary:not(:disabled):not(.disabled):active {
          color: $light-grey;
        }
        &:active,
        &:focus,
        &:hover {
          color: $light-grey;
          box-shadow: none;
        }
        + .dropdown-menu {
          border: 1px solid #dea9aa;
          border-radius: 0;
          padding: 0;
          box-shadow: 0px 2px 7px rgba(67, 63, 63, 0.15);
          li a {
            padding: 10px 1.5rem;
            color: $primary-color;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
    &.b-table.b-table-stacked > tbody > tr td[data-label="Estado"] {
      border-bottom: 1px solid #dee2e6;
    }
    &.b-table.b-table-stacked > tbody > tr[role="row"] {
      @media (max-width: $lg) {
        clear: both;
      }
      > td[data-label="Acciones"] {
        @media (max-width: $lg) {
          display: flex;
          border-top: none;
          float: right;
          order: 1;
          + td {
            display: flex;
            border: none;
            float: right;
            order: 2;
          }
        }
      }
    }
  }
}
</style>
