<template>
  <span :class="tableDataItem" class="status-table-cell"
    ><svg
      v-if="tableDataItem === 'approved'"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-check-circle-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
      /></svg
    ><svg
      v-else-if="tableDataItem === 'pending'"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-exclamation-triangle-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
      /></svg
    ><svg
      v-else-if="tableDataItem === 'rejected'"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-dash-circle-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"
      /></svg
    ><svg
      v-else-if="tableDataItem === 'new'"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-patch-exclamation-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
      /></svg
    >{{ tableDataItem | translate_status }}</span
  >
</template>
<script>
export default {
  name: "StatusTableCell",
  props: {
    tableDataItem: {
      type: String,
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.status-table-cell {
  display: inline-flex;
  align-items: center;
  border: 1px solid $light-grey;
  border-radius: 4px;
  padding: 0px 12px 0px 8px;
  width: 120px;
  height: 25px;
  svg {
    margin-right: 6px;
  }
  &.approved {
    background-color: $success-color-light;
    border-color: $success-color;
    color: $success-color;
  }
  &.pending {
    background-color: $warning-color-light;
    border-color: $warning-color;
    color: $warning-color;
  }
  &.rejected {
    background-color: $error-color-light;
    border-color: $error-color;
    color: $error-color;
  }
  &.new {
    background-color: $focus-color-light;
    border-color: $focus-color;
    color: $focus-color;
    @media (min-width: $lg-min) {
      padding: 0px 12px 0px 14px;
      svg {
        margin-right: 8px;
      }
    }
  }
}
</style>
