<template>
  <div class="no-initial-accreditation">
    <b-container>
      <b-row>
        <b-col class="pl-0" v-if="windowsWidth < 992">
          <AppSidebar />
        </b-col>
        <b-col :cols="12">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[{ text: 'Inicio', href: '/contractor' }]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row>
            <b-col cols="12"
              ><h1 class="contractor-home__h1 float-left">
                {{ $route.params.contractor_name | remove_dash_and_uppercase }}
              </h1>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <img
                class="no-initial-accreditation__img"
                alt="Archivador signo de interrogación"
                src="@/assets/no-obras-asociadas.png"
              />
              <h2>No tienes obras asociadas aún</h2>
              <p>
                Debes hacer la acreditación inicial y así podremos asignarte
                proyectos. Este procedimiento es obligatorio y solo debes
                realizarlo una sola vez.
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import { mapState } from "vuex";

export default {
  name: "ContractorHome",
  components: {
    AppSidebar,
  },
  computed: { ...mapState("front", ["windowsWidth"]) },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.no-initial-accreditation {
  text-align: center;
  &__h1 {
    margin-top: 20px;
    @media (max-width: $lg) {
      margin-top: 0;
    }
  }
  &__img {
    width: 100px;
    margin: 50px auto 30px;
    display: block;
    @media (max-width: $lg) {
      width: 85px;
      margin: 40px auto 29px;
    }
  }
  h2 {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: $dark-grey;
    margin-bottom: 17px;
  }
  p {
    font-size: 15px;
    line-height: 20px;
    color: $dark-grey;
    max-width: 450px;
    display: block;
    margin: 0 auto 50px;
  }
}
</style>
