import APIRequest from "./api.services";

export function getComments(documentId) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      `/api/comments/document/${documentId}/comment/`,
      {},
      (response) => resolve(response),
      (response) => reject(response)
    );
  });
}

export function getChildComments(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      "/api/comments/document/:document_id/comment/:commend_id/"
        .replace(":document_id", parameter.document_id)
        .replace(":commend_id", parameter.commend_id),
      parameter,
      (response) => resolve(response),
      (response) => reject(response)
    );
  });
}

export function createComment(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.post(
      `/api/comments/document/${parameter.documentId}/comment/`,
      { text: parameter.text },
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function removeComment(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.destroy(
      `/api/comments/document/${parameter.documentId}/comment/${parameter.commentId}/`,
      {},
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function postChildComment(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.post(
      `/api/comments/document/${parameter.documentId}/comment/${parameter.commentId}/`,
      { text: parameter.text },
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  getComments,
  postChildComment,
  createComment,
  removeComment,
};
