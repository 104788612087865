var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-documents"},[_c('b-container',[_c('b-row',[(_vm.windowsWidth < 992)?_c('b-col',{staticClass:"pl-0"},[_c('AppSidebar')],1):_vm._e(),_c('b-col',{attrs:{"cols":12}},[_c('b-row',[_c('b-col',[_c('b-breadcrumb',{staticClass:"mt-2",attrs:{"items":[
                {
                  text: 'Inicio',
                  to: {
                    name: 'AssignedProjects',
                  },
                },
                {
                  text: 'Documentos Acreditación',
                } ]}})],1)],1),(_vm.project)?_c('b-row',[_c('b-col',{staticClass:"order-2 order-lg-1",attrs:{"cols":"12","lg":"8"}},[_c('h1',{staticClass:"float-left"},[_vm._v(" "+_vm._s(_vm.project.name)+" ")])]),_c('b-col',{staticClass:"order-2 order-lg-3",attrs:{"cols":"12"}},[(_vm.accredited)?_c('svg',{staticClass:"bi bi-check-circle-fill icon-accreditation",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"}})]):_vm._e(),_c('span',{staticClass:"text title-link-text icon-accreditation"},[_vm._v("Acreditación inicial")])]),_c('b-col',{staticClass:"order-1 order-lg-2",attrs:{"cols":"12","lg":"4"}},[_c('b-button',{staticClass:"float-right btn-primary",class:{
                disabled: _vm.accredited,
              },attrs:{"variant":"primary","to":{
                name: 'InitialAccreditationForm',
                params: {
                  project_slug: _vm.project.slug,
                },
              }}},[_vm._v("Acreditar")])],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('h2',{staticClass:"text-left left-line"},[_vm._v("Filtros")])])],1),_c('b-form',{staticClass:"row",attrs:{"inline":""}},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-input',{attrs:{"placeholder":"Nombre del documento"},model:{value:(_vm.filters.keywords),callback:function ($$v) {_vm.$set(_vm.filters, "keywords", $$v)},expression:"filters.keywords"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-select',{attrs:{"options":_vm.filterStatus},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"form-inline__actions"},[_c('b-link',{staticClass:"uppercase-link align-middle"},[_vm._v("Limpiar")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.search}},[_vm._v("Filtrar")])],1)])],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('h2',{staticClass:"text-left left-line"},[_vm._v("Obras asignadas")])])],1),_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"mt-2",attrs:{"hover":"","items":_vm.documents,"fields":_vm.fields,"stacked":_vm.windowsWidth < 992,"show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" No existen documentos ")]},proxy:true},{key:"cell(date)",fn:function(data){return [_c('span',{staticClass:"first-text"},[_vm._v(_vm._s(_vm._f("date")(data.item.date)))]),_c('span',{staticClass:"second-text"},[_vm._v(_vm._s(_vm._f("dateToMMMMYYYY")(data.item.date)))])]}},{key:"cell(document_type)",fn:function(data){return [_c('p',{staticClass:"document-type-paragraph"},[_vm._v(" "+_vm._s(data.item.document_type)+" ")])]}},{key:"cell(files)",fn:function(data){return [_c('span',{staticClass:"first-text"},[_c('b-link',{attrs:{"to":{
                      name: 'ViewAccreditationDocumentForm',
                      params: {
                        documentId: data.item.id,
                        project_slug: _vm.$route.params.project_slug,
                      },
                    }}},[_vm._v(_vm._s(_vm.getFileQuantity(data.item.files)))])],1),_c('span',{staticClass:"second-text"},[_vm._v(" "+_vm._s(_vm._f("number")(_vm.getFilesTotalSize(data.item.files),"0,0", { thousandsSeparator: "." }))+"KB")])]}},{key:"cell(description)",fn:function(data){return [_c('p',{staticClass:"description-paragraph"},[_vm._v(" "+_vm._s(data.item.description)+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"status-btn remove-btn-styling",attrs:{"title":_vm.$options.filters.translate_status(data.item.status)}},[(data.item.status.code === 'rejected')?_c('minus-circle-icon'):(data.item.status.code === 'approved')?_c('check-circle-icon'):(data.item.status.code === 'does_not_apply')?_c('dots-horizontal-circle-icon'):(data.item.status.code === 'new')?_c('svg',{staticClass:"bi bi-patch-exclamation-fill",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"}})]):_vm._e()],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"actions"},[_c('CommentIconComponent',{attrs:{"number-of-comments":data.item.comments.length,"document-id":data.item.id},on:{"loadComments":_vm.loadComments}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"remove-btn-styling",class:{
                      disabled: data.item.status.code === 'approved',
                    },attrs:{"href":"#","title":"Editar","to":{
                      name: 'EditAccreditationDocumentForm',
                      params: {
                        documentId: data.item.id,
                        project_slug: _vm.$route.params.project_slug,
                      },
                    }}},[_c('pencil-outline-icon')],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"remove-btn-styling",class:{
                      disabled: data.item.status.code === 'approved',
                    },attrs:{"href":"#","title":"Eliminar"},on:{"click":function($event){return _vm.removeDocument(data.item)}}},[_c('delete-outline-icon')],1)],1)]}}])})],1)],1)],1)],1)],1),_c('CommentModal',{attrs:{"documentId":_vm.documentId},on:{"refreshComments":_vm.refreshComments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }