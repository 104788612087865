var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar"},[(_vm.windowsWidth < 992)?_c('b-sidebar',{attrs:{"id":"mobile-sidebar","backdrop":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('b-navbar-nav',[_c('b-nav-text',[_vm._v(_vm._s(_vm.userFullName))]),_c('b-nav-item',{staticClass:"logout",on:{"click":_vm.logout}},[_vm._v("Cerrar sesión")])],1)]},proxy:true}],null,false,713888999)},[(!_vm.isCountractorRoute)?_c('b-nav',{attrs:{"vertical":""}},[(!_vm.isTechnicalInspectorRoute)?_c('b-nav-item',{staticClass:"dashboard",class:{ 'dashboard-active': _vm.$route.name === 'Dashboard' },attrs:{"active-class":"router-link-active","to":{
          name: 'Dashboard',
        }},on:{"click":function($event){return _vm.changeRoute('dashboard')}}},[_vm._v("Dashboard")]):_vm._e(),_vm._l((_vm.projects),function(project){return _c('b-nav-item',{key:project.id,staticClass:"project-item",attrs:{"active-class":"router-link-active","to":{
          name: 'Project',
          params: {
            project_name: project.slug,
          },
        }},on:{"click":function($event){return _vm.changeRoute(project.slug)}}},[_vm._v(_vm._s(project.name))])}),(!_vm.isTechnicalInspectorRoute)?_c('b-nav-item',{staticClass:"administrator",attrs:{"href":"https://buku.cl/admin","target":"_blank"}},[_c('svg',{staticClass:"bi bi-gear-fill",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"}})]),_c('span',[_vm._v("Administrador s")])]):_vm._e()],2):_vm._e()],1):_c('b-nav',{attrs:{"vertical":""}},[(!_vm.isTechnicalInspectorRoute)?_c('b-nav-item',{staticClass:"dashboard",attrs:{"active-class":"router-link-active","to":{
        name: 'Dashboard',
        params: { project_name: '' },
      }},on:{"click":function($event){return _vm.changeRoute('dashboard')}}},[_vm._v("Dashboard")]):_vm._e(),_vm._l((_vm.projects),function(project){return _c('b-nav-item',{key:project.id,staticClass:"project-item",attrs:{"active-class":"router-link-active","to":{
        name: 'Project',
        params: {
          project_slug: project.slug,
        },
      }},on:{"click":function($event){return _vm.changeRoute(project.slug)}}},[_vm._v(_vm._s(project.name))])}),(_vm.isAdmin)?_c('b-nav-item',{staticClass:"administrator",attrs:{"href":"https://buku.cl/admin","target":"_blank"}},[_c('svg',{staticClass:"bi bi-gear-fill",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"}})]),_c('span',[_vm._v("Administrador")])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }