import Vue from "vue";
import axios from "axios";
import defaultConfig from "../config/defaultConfig";
import VueResource from "vue-resource";
//import router from "../router";

import store from "@/store";

/**
 * APIRequest to do external requests, methods and variables in common of all the request
 */
const defaultSucessCallback = () => {};
const defaultErrorCallback = () => {};
const defaultFinallyCallback = () => {};
const defaultHeaders = {
  "Content-Type": "application/json",
};

const APIRequest = {
  /** Delete request
   * path: string; example: /login
   * params: Obj {}
   * successCallback: function
   * errorCallback: function
   */
  destroy: function (
    path,
    params,
    successCallback = defaultSucessCallback,
    errorCallback = defaultErrorCallback,
    finallyCallback = defaultFinallyCallback,
    headers = defaultHeaders
  ) {
    this.setHeaders(headers, path);
    params["headers"] = headers;
    axios
      .delete(`${defaultConfig.urlApi}${path}`, params)
      .then(successCallback)
      .catch(errorCallback)
      .finally(finallyCallback);
  },
  getAxios: function (
    path,
    params,
    successCallback = defaultSucessCallback,
    errorCallback = defaultErrorCallback,
    finallyCallback = defaultFinallyCallback,
    headers = defaultHeaders,
    othersConfig = null
  ) {
    headers = this.setHeaders(headers);
    return axios({
      method: "get",
      url: `${defaultConfig.urlApi}${path}`,
      ...othersConfig,
      headers,
    })
      .then(successCallback)
      .catch(errorCallback)
      .finally(finallyCallback);
  },

  getBlobAxios: function (
    path,
    params,
    successCallback = defaultSucessCallback,
    errorCallback = defaultErrorCallback,
    finallyCallback = defaultFinallyCallback,
    headers = defaultHeaders,
    othersConfig = null
  ) {
    headers = this.setHeaders(headers);
    return axios({
      method: "get",
      url: `${defaultConfig.urlApi}${path}`,
      ...othersConfig,
      headers,
    })
      .then(successCallback)
      .catch(errorCallback)
      .finally(finallyCallback);
  },

  post: function (
    path,
    params,
    successCallback = defaultSucessCallback,
    errorCallback = defaultErrorCallback,
    finallyCallback = defaultFinallyCallback,

    headers = path.startsWith("/api/auth/")
      ? {
          "Content-Type": "application/json",
        }
      : defaultHeaders
  ) {
    this.setHeaders(headers, path);
    axios
      .post(`${defaultConfig.urlApi}${path}`, params, { headers })
      .then(successCallback)
      .catch(errorCallback)
      .finally(finallyCallback);
  },

  /** Put request
   * path: string; example: /login
   * params: Obj {}
   * successCallback: function
   * errorCallback: function
   */
  put: function (
    path,
    params,
    successCallback = defaultSucessCallback,
    errorCallback = defaultErrorCallback,
    finallyCallback = defaultFinallyCallback,
    headers = defaultHeaders
  ) {
    this.setHeaders(headers, path);
    axios
      .put(`${defaultConfig.urlApi}${path}`, params, { headers })
      .then(successCallback)
      .catch(errorCallback)
      .finally(finallyCallback);
  },

  /** Post request
   * path: string; example: /login
   * params: Obj {}
   * successCallback: function
   * errorCallback: function
   */
  setHeaders: function (headers = {}, path) {
    const token = store.getters["auth/token"];
    if (path && path.startsWith("/api/auth/")) {
      delete Vue.http.headers.common["Authorization"];
      headers = {};
    } else if (token) {
      Vue.http.headers.common["Authorization"] = token;
      headers = Object.assign(headers, {
        Authorization: token,
      });
    } else {
      delete Vue.http.headers.common["Authorization"];
      headers = {};
    }
    return headers;
  },
};

Vue.use(VueResource);

export default APIRequest;
