<template>
  <div class="initial-accreditation-form">
    <b-container>
      <b-row>
        <b-col class="pl-0" v-if="windowsWidth < 992">
          <AppSidebar />
        </b-col>
        <b-col cols="12">
          <b-row v-if="project">
            <b-col cols="8"
              ><b-breadcrumb
                class="mt-2"
                :items="[
                  { text: 'Inicio', to: { name: 'Dashboard' } },
                  {
                    text: project.name,
                    to: {
                      name: 'Project',
                      params: {
                        project_slug: project.slug,
                      },
                    },
                  },
                  {
                    text: contractor.name,
                    to: {
                      name: 'Documents',
                      params: {
                        contractor_slug: contractor.slug,
                        project_slug: project.slug,
                      },
                    },
                  },
                  {
                    text: 'Editar documentos',
                  },
                ]"
              ></b-breadcrumb
            ></b-col>
            <b-col cols="4"
              ><b-link
                class="breadcrumb mt-2 float-right"
                @click="$router.go(-1)"
                >Volver</b-link
              >
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="8"
              ><h1 class="initial-accreditation-form__h1 float-left">
                Editar documento
              </h1>
            </b-col>
          </b-row>

          <b-row
            v-if="project"
            class="
              justify-content-center
              mt-5
              mb-4
              initial-accreditation-form__upper-select
            "
          >
            <b-col cols="12" lg="10">
              <b-row class="align-items-center">
                <b-col cols="12" lg="8"
                  ><h2 class="initial-accreditation-form__h2 float-left">
                    {{ project.name }}
                  </h2></b-col
                >
                <b-col cols="12" lg="4"
                  ><b-form-select
                    ref="file-input"
                    :value="document.period"
                    :options="periods"
                    value-field="period"
                    @change="changePeriod"
                  ></b-form-select
                ></b-col>
              </b-row> </b-col
          ></b-row>

          <!-- F-30 actualizado, debe tener fecha posterior al pago imposiciones -->
          <div class="initial-accreditation-form__item" v-if="document">
            <b-row class="initial-accreditation-form__item__form-title">
              <b-col cols="12" lg="10"
                ><h2>
                  {{ document.document_type }}
                </h2></b-col
              ></b-row
            >
            <b-row class="initial-accreditation-form__item__form-content">
              <b-col cols="12" lg="5">
                <b-form-file
                  :value="document.binaryFiles"
                  placeholder="Seleccionar archivo"
                  drop-placeholder="Seleccionar archivo"
                  @input="onUploadDocumentFile(document, $event)"
                  multiple
                ></b-form-file>
                <p class="file-description">
                  Puedes subir varios archivos. Peso máximo por archivo 100mb.
                  Formatos permitidos .rar .zip .doc .docx .pdf .jpg...
                </p>
                <div
                  v-if="
                    document.files &&
                    document.files.length &&
                    document.files.length > 0
                  "
                  class="file-list"
                >
                  <span
                    class="link_file"
                    @click.prevent="downloadfile(file.url)"
                    v-for="(file, index) in document.files"
                    :key="index"
                    ><img src="@/assets/paperclip.png" alt="Clip de papel" />{{
                      file ? file.name : ""
                    }}</span
                  >
                </div></b-col
              ><b-col cols="12" lg="5"
                ><label for="description">Descripción</label>
                <b-form-textarea
                  id="description"
                  placeholder="Escribe aquí..."
                  v-model="document.description"
                ></b-form-textarea>
                <label for="description">Creación:</label>
                <p>{{ document.created_at | date }}</p>
                <label
                  for="description"
                  v-if="
                    document.status_changes &&
                    document.status_changes.length > 0
                  "
                  >Aprobaciones:</label
                >
                <p v-for="changes in document.status_changes" :key="changes.id">
                  {{ changes.user }} - {{ changes.status }} -
                  {{ changes.created_at | date }}
                </p>
              </b-col></b-row
            >
          </div>

          <div v-if="!document">
            <b-row class="initial-accreditation-form__item__form-title">
              <b-col cols="12" lg="10"
                ><h2>El documento no existe en este periodo</h2></b-col
              ></b-row
            >
          </div>
          <!-- Acciones -->
          <div class="actions-section" v-if="document">
            <b-row>
              <b-col cols="12" lg="6" class="order-2 order-lg-1"
                ><b-link @click="$router.go(-1)">Volver</b-link></b-col
              ><b-col cols="12" lg="6" class="order-1 order-lg-2"
                ><b-button
                  @click="saveDocument"
                  variant="primary"
                  :disabled="document.status.code == 'approved'"
                >
                  Guardar
                </b-button></b-col
              ></b-row
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import { mapActions, mapState } from "vuex";
import router from "@/router";
import tablesServices from "@/services/tables.services";
import documentsServices from "@/services/documents.services";

export default {
  name: "EditDocumentForm",
  components: {
    AppSidebar,
  },
  computed: {
    ...mapState("front", ["windowsWidth"]),

    localUploadDocumentForm: {
      get() {
        return this.uploadDocumentForm;
      },
      set(newUploadDocumentForm) {
        this.$v.localUploadDocumentForm.user.$touch();
        return newUploadDocumentForm;
      },
    },
    projectsSelect() {
      const selectElements = this.projects.map((v) => ({
        value: v.id,
        text: v.name,
      }));
      selectElements.unshift({ value: null, text: "Selecciona un proyecto" });
      return selectElements;
    },
  },
  data() {
    return {
      project: null,
      contractor: null,
      periods: [],
      selectedProjectDate: null,
      document: null,
    };
  },
  mounted() {
    let step1 = tablesServices.getProject(this.$route.params.project_slug);
    let step2 = tablesServices.getContractor(
      this.$route.params.contractor_slug
    );
    let step3 = documentsServices.loadPeriods();
    let step4 = null;

    if (this.$route.params.period == undefined) {
      step4 = documentsServices.getDocument({
        projectSlug: this.$route.params.project_slug,
        documentId: this.$route.params.documentId,
      });
    } else {
      step4 = documentsServices.getDocumentByPeriod({
        projectSlug: this.$route.params.project_slug,
        documentId: this.$route.params.documentId,
        period: this.$route.params.period,
      });
    }

    Promise.all([step1, step2, step3, step4]).then((values) => {
      this.project = values[0];
      this.contractor = values[1];
      this.periods = values[2];
      this.selectedProjectDate = this.periods[0].period;
      this.document = values[3];
    });
  },
  methods: {
    ...mapActions("documents", [
      "uploadDocument",
      "updateDocument",
      "uploadDocumentFileSingleResponse",
      "loadPeriods",
      "getDocument",
    ]),
    ...mapActions("tables", ["getDocuments", "getContractor", "getProject"]),
    changePeriod(period) {
      console.log("Change Period:", period);
      documentsServices
        .getDocumentByPeriod({
          projectSlug: this.$route.params.project_slug,
          documentId: this.$route.params.documentId,
          period: period,
        })
        .then(
          (document) => {
            this.document = document;
          },
          () => {
            this.document = null;

            this.$bvToast.toast(
              "Documento no ha sido encontrado para el periodo especificado",
              {
                title: "Error",
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              }
            );
          }
        );
    },

    downloadfile(url) {
      window.open(url);
    },
    onUploadDocumentFile(document, binaryFiles) {
      if (binaryFiles.length === 0) {
        return;
      }

      documentsServices
        .uploadDocFile({ files: binaryFiles, projectId: this.project.id })
        .then((files) => {
          document.files = files.concat(document.files);
        });
    },
    notifyUpload() {
      this.$bvToast.toast("Se han guardado el documento.", {
        title: "Acreditación Inicial",
        toaster: "b-toaster-top-center",
        solid: true,
        appendToast: false,
        variant: "success",
      });
    },
    saveDocument() {
      documentsServices
        .updateDocument({
          projectSlug: this.project.slug,
          period: this.document.period,
          document: this.document,
        })
        .then(() => {
          router
            .push({
              name: "Documents",
              params: {
                contractor_slug: this.contractor.slug,
                project_slug: this.project.slug,
              },
            })
            .then(() => this.notifyUpload());
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";
.link_file {
  cursor: pointer;
}
.initial-accreditation-form {
  text-align: center;
  &__h1 {
    margin-top: 20px;
    @media (max-width: $lg) {
      margin-top: 0;
    }
  }
  &__h2 {
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 0;
    color: $dark-grey;
  }
  &__upper-select {
    @media (max-width: $lg) {
      .col-12 {
        &:first-of-type {
          margin-bottom: 10px;
        }
      }
    }
  }
  &__title-paragraph {
    margin-top: 10px;
    @media (max-width: $lg) {
      text-align: left;
    }
    a,
    a:not([href]):not([class]) {
      text-decoration: underline;
      color: $primary-color;
      font-weight: bold;
    }
  }
  &__item {
    padding-top: 30px;
    padding-bottom: 50px;
    border-bottom: 1px solid #d9d9d9;
    @media (max-width: $lg) {
      padding-bottom: 30px;
    }
    &--last {
      border-bottom: none;
    }
    &__form-title,
    &__form-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: left;
    }
    &__form-title {
      h2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 18px;
        color: $dark-grey;
      }
    }
    &__form-content {
      .col-5 {
        @media (min-width: $lg-min) {
          &:first-of-type {
            padding-right: 30px;
          }
          &:last-of-type {
            padding-left: 30px;
          }
        }
      }
      .file-list {
        font-size: 15px;
        line-height: 22px;
        color: $dark-grey;
        margin-top: 40px;
        span {
          display: block;
          img {
            width: 13.94px;
            height: 13.94px;
            margin-right: 8px;
            margin-bottom: 2px;
          }
        }
      }
      .btn-secondary {
        margin-bottom: 20px;
        width: 100%;
        @media (min-width: $lg-min) {
          width: 288px;
          margin-top: 5px;
        }
      }
      .file-description {
        font-size: 13px;
        line-height: 15px;
        color: $dark-grey;
        @media (max-width: $lg) {
          margin-bottom: 25px;
        }
      }
      label {
        font-size: 15px;
        line-height: 15px;
        font-weight: bold;
        color: $darkest-grey;
        @media (max-width: $lg) {
          opacity: 0;
        }
      }
      .custom-checkbox {
        .custom-control-label {
          line-height: 24px;
          font-weight: normal;
          &:before,
          &:after {
            top: 0.1rem;
          }
          @media (max-width: $lg) {
            opacity: 1;
          }
        }
      }
      textarea {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        &:focus {
          border-color: #dea9aa;
          box-shadow: 0 0 0 0.2rem rgb(222 169 170 / 25%);
        }
        &.form-control::placeholder {
          color: #dea9aa;
          font-size: 16px;
          line-height: 20px;
          opacity: 1;
        }

        &.form-control:-ms-input-placeholder {
          color: #dea9aa;
          font-size: 16px;
          line-height: 20px;
        }

        &.form-control::-ms-input-placeholder {
          color: #dea9aa;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  .actions-section {
    padding-top: 99px;
    padding-bottom: 74px;
    @media (max-width: $lg) {
      padding-top: 20px;
      padding-bottom: 112px;
    }
  }
}
</style>
