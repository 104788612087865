var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project"},[_c('b-container',[_c('b-row',[(_vm.windowsWidth < 992)?_c('b-col',{staticClass:"pl-0"},[_c('AppSidebar')],1):_vm._e(),_c('b-col',{attrs:{"cols":12}},[_c('b-row',[_c('b-col',[_c('b-breadcrumb',{staticClass:"mt-2",attrs:{"items":[{ text: 'Inicio', href: 'project/' }]}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"order-2 order-lg-1",attrs:{"cols":"12","lg":"8"}},[_c('h1',{staticClass:"float-left"},[_vm._v("Proyectos")])])],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('h2',{staticClass:"text-left left-line"},[_vm._v("Filtro")])])],1),_c('b-form',{staticClass:"row",attrs:{"inline":""}},[_c('b-col',{attrs:{"cols":"12","lg":"9"}},[_c('b-form-input',{attrs:{"placeholder":"Buscar obra o arquitecto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},model:{value:(_vm.filterValue2),callback:function ($$v) {_vm.filterValue2=$$v},expression:"filterValue2"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('div',{staticClass:"form-inline__actions"},[_c('b-link',{staticClass:"uppercase-link align-middle",on:{"click":_vm.cleanFilter}},[_vm._v(" Limpiar")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.search}},[_vm._v("Filtrar")])],1)])],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('h2',{staticClass:"text-left left-line"},[_vm._v("Obras asignadas")])])],1),_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"mt-2",attrs:{"hover":"","items":_vm.searchProjects,"fields":_vm.fields,"stacked":_vm.windowsWidth < 992},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-link',{staticClass:"table__action-link",attrs:{"to":{
                    name: 'ProjectAccreditationDocuments',
                    params: {
                      project_slug: data.item.slug,
                    },
                  }}},[(data.item.accredited)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Acreditación")]):_vm._e()],1),_c('b-link',{staticClass:"table__action-link mr-2",attrs:{"to":{
                    name: 'ProjectAccreditationDocuments',
                    params: {
                      project_slug: data.item.slug,
                    },
                  }}},[(!data.item.accredited)?_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v("Acreditación")]):_vm._e()],1),_c('b-link',{staticClass:"table__action-link mr-2",attrs:{"to":{
                    name: 'ProjectDocuments',
                    params: {
                      project_slug: data.item.slug,
                    },
                  }}},[_c('b-badge',{attrs:{"variant":"light"}},[_vm._v("Mensuales")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }