import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

import Login from "../views/Login.vue";
import Dashboard from "../views/M3/Dashboard.vue";
import Documents from "../views/M3/Documents.vue";
import AccreditationDocuments from "../views/M3/AccreditationDocuments.vue";
import Project from "../views/M3/Project.vue";
import UploadDocumentFormM3 from "../views/M3/UploadDocumentFormM3.vue";

import InitialAccreditationFormM3 from "../views/M3/InitialAccreditationFormM3.vue";
import EditDocumentFormM3 from "../views/M3/EditDocumentFormM3.vue";
import EditAccreditationDocumentFormM3 from "../views/M3/EditAccreditationDocumentFormM3.vue";

import ContractorHome from "../views/Contractor/ContractorHome.vue";
import AssignedProjects from "../views/Contractor/AssignedProjects.vue";
import InitialAccreditationForm from "../views/Contractor/InitialAccreditationForm.vue";
import ProcessingProjectAssignment from "../views/Contractor/ProcessingProjectAssignment.vue";
import ProjectDocuments from "../views/Contractor/ProjectDocuments.vue";
import ProjectAccreditationDocuments from "../views/Contractor/ProjectAccreditationDocuments.vue";
import UploadDocumentForm from "../views/Contractor/UploadDocumentForm.vue";
import EditDocumentForm from "../views/Contractor/EditDocumentForm.vue";
import ViewDocumentForm from "../views/Contractor/ViewDocumentForm.vue";
import EditAccreditationDocumentForm from "../views/Contractor/EditAccreditationDocumentForm.vue";
import ViewAccreditationDocumentForm from "../views/Contractor/ViewAccreditationDocumentForm.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/new-password-form",
    name: "ChangePasswordForm",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  /** M3 */
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:project_slug/:contractor_slug/upload-document",
    name: "UploadDocumentFormM3",
    component: UploadDocumentFormM3,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:project_slug/:contractor_slug/initial-accreditation-form",
    name: "InitialAccreditationFormM3",
    component: InitialAccreditationFormM3,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:project_slug/:contractor_slug/edit-document/:documentId",
    name: "EditDocumentFormM3",
    component: EditDocumentFormM3,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:project_slug/:contractor_slug/edit-document/:documentId/:period",
    name: "EditDocumentFormPeriodM3",
    component: EditDocumentFormM3,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:project_slug/:contractor_slug/edit-accreditation-document/:documentId",
    name: "EditAccreditationDocumentFormM3",
    component: EditAccreditationDocumentFormM3,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:project_slug",
    name: "Project",
    component: Project,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:project_slug/:contractor_slug",
    name: "Documents",
    component: Documents,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:project_slug/:contractor_slug/accreditation",
    name: "AccreditationDocuments",
    component: AccreditationDocuments,
    meta: {
      requiresAuth: true,
    },
  },
  /** ./M3 */
  /** Contractors */
  {
    path: "/contractor",
    name: "ContractorHome",
    component: ContractorHome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/initial-accreditation-form/:project_slug",
    name: "InitialAccreditationForm",
    component: InitialAccreditationForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/:contractor_name/processing-project-assignment",
    name: "ProcessingProjectAssignment",
    component: ProcessingProjectAssignment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/assigned-projects",
    name: "AssignedProjects",
    component: AssignedProjects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/acreditation_documents/:project_slug",
    name: "ProjectAccreditationDocuments",
    component: ProjectAccreditationDocuments,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/documents/:project_slug",
    name: "ProjectDocuments",
    component: ProjectDocuments,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/:project_slug/upload-document",
    name: "UploadDocumentForm",
    component: UploadDocumentForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/:project_slug/edit-document/:documentId",
    name: "EditDocumentForm",
    component: EditDocumentForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/:project_slug/view-document/:documentId",
    name: "ViewDocumentForm",
    component: ViewDocumentForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/:project_slug/edit-accreditation-document/:documentId",
    name: "EditAccreditationDocumentForm",
    component: EditAccreditationDocumentForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractor/:project_slug/view-accreditation-document/:documentId",
    name: "ViewAccreditationDocumentForm",
    component: ViewAccreditationDocumentForm,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const token = store.getters["auth/token"];
  const user = store.getters["auth/user"];

  const userIsEmpty =
    user == null || Object.values(user).every((o) => o === null || o === "");
  if (requiresAuth && userIsEmpty && !token) {
    next({ path: "/" });
    // } else if (!requiresAuth && !userIsEmpty && token && to.path === "/") {
    //   next({ name: "Dashboard" });
  } else {
    next();
  }
});

export default router;
