import commentsServices from "@/services/comments.services";

const comments = {
  namespaced: true,
  state: {
    comments: [],
    commentModalId: null,
  },
  mutations: {
    setComments(state, { comments }) {
      state.comments = comments;
    },
    setCommentModalId(state, { commentModalId }) {
      state.commentModalId = commentModalId;
    },
  },
  actions: {
    createComment({ dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        commentsServices.postComment(
          { document_id: params.documentId, text: params.text },
          ({ data }) => {
            dispatch("showComments", state.commentModalId);
            resolve(data);
          },
          (err) => reject(err),
          () => {}
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    createChildComment({ dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        commentsServices.postChildComment(
          {
            comment_id: params.commentId,
            document_id: params.documentId,
            text: params.text,
          },
          ({ data }) => {
            resolve(data);
          },
          (err) => reject(err),
          () => {}
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    deleteComment({ dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        commentsServices
          .destroy(id)
          .then((response) => {
            dispatch("showComments");
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
    // editComment({ dispatch }, { params }) {
    //   return new Promise((resolve, reject) => {
    //     commentsServices
    //       .update({ id: params.id, comment: params.comment })
    //       .then((response) => {
    //         dispatch("showComments");
    //         resolve(response);
    //       })
    //       .catch((err) => reject(err));
    //   });
    // },
    // eslint-disable-next-line no-unused-vars
    showComments({ commit }, documentId) {
      return new Promise((resolve, reject) => {
        commentsServices.getComments(
          { document_id: documentId },
          ({ data }) => {
            commit("setComments", { comments: data });
            resolve(data);
          },
          (err) => reject(err),
          () => {}
        );
        // commentsServices
        //   .show(rootState.business.selectedBusiness.id)
        //   .then((response) => {
        //     commit("SET_COMMENTS", { comments: response.data.comments });
        //     resolve(response);
        //   })
        //   .catch((err) => reject(err));
      });
    },
  },
};

export default comments;
