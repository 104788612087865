<template>
  <div class="initial-accreditation-form edit-document-form">
    <b-container>
      <b-row>
        <b-col class="pl-0" v-if="windowsWidth < 992">
          <AppSidebar />
        </b-col>
        <b-col cols="12" v-if="project && document">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[
                  {
                    text: 'Inicio',
                    to: {
                      name: 'AssignedProjects',
                    },
                  },
                  {
                    text: 'Documentos',
                    to: {
                      name: 'ProjectDocuments',
                    },
                    params: {
                      project_slug: project.slug,
                    },
                  },
                  { text: 'Editar documento', href: '#' },
                ]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row>
            <b-col
              ><h1 class="initial-accreditation-form__h1 float-left">
                Editar documentos
              </h1>
            </b-col>
          </b-row>

          <b-row
            v-if="project && document"
            class="
              justify-content-center
              mt-5
              mb-4
              initial-accreditation-form__upper-select
            "
          >
            <b-col cols="12" lg="10">
              <b-row>
                <b-col cols="12" lg="8"
                  ><h2 class="initial-accreditation-form__h2 float-left">
                    {{ project.name }}
                  </h2></b-col
                >
              </b-row>
            </b-col></b-row
          >

          <!-- F-30 actualizado, debe tener fecha posterior al pago imposiciones -->
          <div class="initial-accreditation-form__item">
            <b-row class="initial-accreditation-form__item__form-title">
              <b-col cols="12" lg="10"
                ><h2>
                  {{ document.document_type }}
                </h2></b-col
              ></b-row
            >
            <b-row class="initial-accreditation-form__item__form-content">
              <b-col cols="12" lg="10">
                <div
                  v-if="
                    document.files &&
                    document.files.length &&
                    document.files.length > 0
                  "
                  class="file-list"
                >
                  <span
                    class="link_file"
                    @click.prevent="downloadfile(file.url)"
                    v-for="(file, index) in document.files"
                    :key="index"
                    ><img src="@/assets/paperclip.png" alt="Clip de papel" />{{
                      file ? file.name : ""
                    }}</span
                  >
                </div></b-col
              ></b-row
            >
          </div>

          <!-- Acciones -->
          <div class="actions-section">
            <b-row>
              <b-col cols="12" lg="6" class="order-2 order-lg-1"
                ><b-link @click="$router.go(-1)">Volver</b-link></b-col
              ></b-row
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import { mapState } from "vuex";
import router from "@/router";
import tablesServices from "@/services/tables.services";
import documentsServices from "@/services/documents.services";

export default {
  name: "EditDocumentForm",
  components: {
    AppSidebar,
  },
  data() {
    return {
      project: null,
      document: null,
    };
  },
  computed: {
    ...mapState("front", ["windowsWidth"]),
  },
  mounted() {
    let step1 = tablesServices.getProject(this.$route.params.project_slug);
    let step2 = documentsServices.getDocument({
      projectSlug: this.$route.params.project_slug,
      documentId: this.$route.params.documentId,
    });

    Promise.all([step1, step2]).then((values) => {
      this.project = values[0];
      this.document = values[1];
    });
  },
  methods: {
    onUploadDocumentFile(document, binaryFiles) {
      if (binaryFiles.length === 0) {
        return;
      }

      documentsServices
        .uploadDocFile({ files: binaryFiles, projectId: this.project.id })
        .then((files) => {
          document.files = files.concat(document.files);
        });
    },
    notifyUpload() {
      this.$bvToast.toast("Se han guardado el documento.", {
        title: "Acreditación Inicial",
        toaster: "b-toaster-top-center",
        solid: true,
        appendToast: false,
        variant: "success",
      });
    },
    downloadfile(url) {
      window.open(url);
    },
    saveDocument() {
      documentsServices
        .updateDocument({
          projectSlug: this.project.slug,
          document: this.document,
        })
        .then(() => {
          router
            .push({
              name: "ProjectDocuments",
              params: {
                project_slug: this.project.slug,
              },
            })
            .then(() => this.notifyUpload());
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";
.link_file {
  cursor: pointer;
}
.initial-accreditation-form.edit-document-form {
  .actions-section {
    @media (max-width: $lg) {
      padding-top: 50px;
      padding-bottom: 40px;
    }
  }
}
</style>
