<template>
  <b-modal
    id="comment-modal"
    title="Comentarios"
    class="comments-modal"
    size="md"
    ref="commentsModal"
    hide-footer
    @show="showModal"
  >
    <b-row>
      <b-col>
        <b-form-textarea
          v-model="text"
          placeholder="Escribe aquí..."
        ></b-form-textarea>
        <p class="count">{{ text.length }}/500</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        ><b-button
          @click="onCreateComment"
          variant="primary"
          :disabled="!(text && text.length && text.length > 0)"
          >Publicar</b-button
        ></b-col
      ></b-row
    >

    <div
      v-show="!(Array.isArray(comments) && comments.length)"
      class="no-comments"
    >
      <img src="@/assets/no-comments.png" alt="Ícono archivador" />
      <p>No hay comentarios aún</p>
    </div>

    <div
      v-show="comments && comments.length && comments.length > 0"
      class="comments-list-container"
    >
      <div
        class="comments-list"
        :class="{ 'comments-list--open': commentsListIsOpen }"
      >
        <div
          v-for="(comment, index) in comments"
          :key="index"
          :class="{ last: index === Object.keys(comments).length - 1 }"
        >
          <div class="comments-list__comment">
            <div class="comments-list__comment__meta">
              <div class="user">{{ getUserName(comment) }}</div>
              <div class="date">{{ comment.created_at | date }}</div>
            </div>
            <p>{{ comment.text }}</p>
            <b-dropdown v-b-tooltip.hover class="remove-btn-styling" right>
              <template #button-content
                ><dots-vertical-icon></dots-vertical-icon
              ></template>
              <b-dropdown-item @click="toggleActive(comment)"
                >Responder</b-dropdown-item
              >
              <b-dropdown-item @click="onRemoveComment(comment)">
                Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            class="comments-list__comment__textarea"
            v-if="activeComment[comment.id]"
          >
            <b-row>
              <b-col cols="12" lg="9">
                <b-form-textarea
                  placeholder="Escribe aquí..."
                  @input="onUpdateActiveCommentText($event, comment.id)"
                  :value="activeComment[comment.id].text"
                ></b-form-textarea>
                <p class="count">
                  {{ activeComment[comment.id].text.length }}/500
                </p>
              </b-col>
              <b-col cols="12" lg="3">
                <b-button
                  @click="onCreateChildComment(comment, $event)"
                  :disabled="!activeComment[comment.id].text"
                  variant="primary"
                  >Responder</b-button
                >
              </b-col>
            </b-row>
          </div>
          <div v-for="subcomment in comment.children" :key="subcomment.id">
            <div class="comments-list__comment subcomment">
              <div class="comments-list__comment__meta">
                <div class="user">{{ getUserName(subcomment) }}</div>
                <div class="date">{{ subcomment.created_at | date }}</div>
              </div>
              <p>x{{ subcomment.text }}</p>
              <b-dropdown class="remove-btn-styling" right>
                <template #button-content
                  ><dots-vertical-icon></dots-vertical-icon
                ></template>
                <b-dropdown-item @click="onRemoveComment(subcomment)">
                  Eliminar
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div ref="bottomListContainer" id="bottom-list-container"></div>
    </div>

    <!--div
      v-if="comments && comments.length && comments.length > 3"
      class="see-more"
      :class="{ 'see-more--open': commentsListIsOpen }"
    >
      <a @click="commentsListIsOpen = !commentsListIsOpen">
        <img src="@/assets/arrow-right.png" alt="Flecha dirección derecha" />
        {{ comments.length - 3 }} Respuestas
      </a>
      <p class="see-more__last-answer">
        La última respuesta se publicó hoy a las 19:27
      </p>
    </div-->
  </b-modal>
</template>
<script>
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical.vue";
import commentsServices from "@/services/comments.services";

export default {
  name: "CommentModal",
  components: {
    DotsVerticalIcon,
  },
  props: {
    documentId: {
      type: Number,
    },
  },
  data() {
    return {
      activeComment: {},
      commentsListIsOpen: false,
      text: "",
      comments: [],
    };
  },
  computed: {},
  methods: {
    getUserName(comment) {
      if (
        comment.owner &&
        comment.owner.first_name &&
        comment.owner.first_name.length &&
        comment.owner.first_name.length > 0 &&
        comment.owner.last_name &&
        comment.owner.last_name.length &&
        comment.owner.last_name.length > 0
      ) {
        return `${comment.owner.first_name} ${comment.owner.last_name}`;
      } else if (
        comment.owner &&
        comment.owner.first_name &&
        comment.owner.first_name.length &&
        comment.owner.first_name.length > 0
      ) {
        return `${comment.owner.first_name}`;
      } else if (
        comment.owner &&
        comment.owner.last_name &&
        comment.owner.last_name.length &&
        comment.owner.last_name.length > 0
      ) {
        return `${comment.owner.last_name}`;
      } else {
        return `Usuario sin nombre`;
      }
    },
    onCreateComment() {
      commentsServices
        .createComment({
          documentId: this.documentId,
          text: this.text,
        })
        .then(() => {
          this.refreshComments(true);
        });
    },
    refreshComments(scroll) {
      return new Promise((resolve) => {
        commentsServices.getComments(this.documentId).then(({ data }) => {
          this.comments = data;
          this.text = "";

          if (scroll) {
            this.scrollToElement();
          }
          resolve();
          this.$emit("refreshComments", this.comments);
        });
      });
    },
    onCreateChildComment(comment) {
      commentsServices
        .postChildComment({
          commentId: comment.id,
          documentId: this.documentId,
          text: this.activeComment[comment.id].text,
        })
        .then(() => {
          this.refreshComments(true).then(() => {
            this.toggleActive(comment);
          });
        });
    },
    onUpdateActiveCommentText(value, commentId) {
      this.activeComment[commentId].text = value;
    },
    scrollToElement() {
      const el = this.$refs.bottomListContainer;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    toggleActive(comment) {
      if (this.activeComment[comment.id]) {
        this.removeActiveComment(comment);
        return;
      }

      this.addActiveComments(comment);
    },
    addActiveComments(comment) {
      this.activeComment = Object.assign({}, this.activeComment, {
        [comment.id]: { ...comment, text: "" },
      });
    },
    onRemoveComment(comment) {
      commentsServices
        .removeComment({
          documentId: this.documentId,
          commentId: comment.id,
        })
        .then(() => {
          this.refreshComments(false);
        });
    },
    removeActiveComment(comment) {
      delete this.activeComment[comment.id];
      this.activeComment = Object.assign({}, this.activeComment);
    },
    showModal() {},
  },
  watch: {
    documentId: function (newDocumentId) {
      if (newDocumentId !== null) {
        commentsServices.getComments(newDocumentId).then(({ data }) => {
          this.comments = data;
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

#comment-modal___BV_modal_outer_ {
  .modal-dialog {
    @media (min-width: $lg-min) {
      max-width: 600px;
    }
  }
  .modal-header {
    padding: 16px 1rem 14px 1rem;
    border: none;
    .modal-title {
      font-weight: bold;
      font-size: 35px;
      line-height: 43px;
      color: #333333;
    }
    .close {
      color: $primary-color;
    }
  }
  .modal-body {
    padding-top: 0;
    textarea {
      &.form-control {
        min-height: 89px;
      }
      + .count {
        font-size: 12px;
        line-height: 15px;
        color: #929a9d;
        margin-top: 5px;
      }
    }
    .btn.btn-primary {
      @media (min-width: $lg) {
        float: right;
        width: 110px;
      }
    }
    .no-comments {
      padding-top: 32px;
      padding-bottom: 79px;
      img {
        display: block;
        margin: 0 auto 37px;
        @media (min-width: $lg) {
          width: 100px;
        }
        + p {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
    .comments-list-container {
      margin-top: 15px;
      height: auto;
      max-height: 208px;
      overflow: auto;
    }
    .comments-list {
      overflow: hidden;
      .last .comments-list__comment {
        margin-bottom: 0;
      }
      &__comment {
        position: relative;
        background-color: #fbf3f4;
        border-radius: 5px;
        padding: 8px 10px 9px;
        margin-bottom: 11px;
        &.subcomment {
          margin-left: 30px;
        }
        &__meta {
          margin-bottom: 2px;
          .user,
          .date {
            display: inline-block;
          }
          .user {
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: $dark-grey;
            &:after {
              content: "•";
              font-size: 12px;
              padding-left: 7px;
              padding-right: 7px;
            }
          }
          .date {
            font-size: 12px;
            line-height: 20px;
            color: $light-grey;
          }
        }
        &__textarea {
          margin-bottom: 21px;
          @media (min-width: $lg-min) {
            .col-lg-9.col-12 {
              padding-right: 0;
            }
          }
          textarea.form-control {
            min-height: 43px;
            height: 43px;
          }
          .count {
            margin-bottom: 0;
          }
        }
        p {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 0;
        }
        .dropdown {
          position: absolute;
          top: 10px;
          right: 0;
          &.show > .btn-secondary.dropdown-toggle {
            color: $light-grey;
            &:focus {
              box-shadow: none;
            }
          }
          > .dropdown-toggle {
            color: $light-grey;
            &.btn-secondary:not(:disabled):not(.disabled):active {
              color: $light-grey;
            }
            &:active,
            &:focus,
            &:hover {
              color: $light-grey;
              box-shadow: none;
            }
            + .dropdown-menu {
              border: 1px solid #dea9aa;
              border-radius: 0;
              padding: 0;
              box-shadow: 0px 2px 7px rgba(67, 63, 63, 0.15);
              min-width: 95px;
              li a {
                padding: 5px 1.5rem;
                color: $primary-color;
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
      &--open {
        height: 280px;
        overflow-y: scroll;
        padding-right: 4px;
        width: calc(100% + 10px);
        .comments-list__comment {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        @media (max-width: $lg) {
          margin-bottom: 10px;
        }
      }
    }
    .see-more {
      @media (max-width: $lg) {
        margin-top: 5px;
      }
      &--open {
        opacity: 0;
        @media (max-width: $lg) {
          display: none;
        }
      }
      a {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: $primary-color;
        cursor: pointer;
        img {
          width: 15.16px;
          height: 13.99px;
        }
      }
      &__last-answer {
        &:before {
          @media (min-width: $lg-min) {
            content: "|";
          }
        }
        font-size: 12px;
        line-height: 14px;
        color: $light-grey;
        display: inline-block;
      }
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 2.5px;
    background: #fbf3f4;
    border: 1px solid #dea9aa;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 2.5px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
