var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"initial-accreditation-form"},[_c('b-container',[_c('b-row',[(_vm.windowsWidth < 992)?_c('b-col',{staticClass:"pl-0"},[_c('AppSidebar')],1):_vm._e(),(_vm.project)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',[_c('b-breadcrumb',{staticClass:"mt-2",attrs:{"items":[
                {
                  text: 'Inicio',
                  to: {
                    name: 'AssignedProjects',
                  },
                },
                {
                  text: 'Documentos ',
                  to: {
                    name: 'ProjectDocuments',
                  },
                  params: {
                    project_slug: _vm.project.slug,
                  },
                },
                { text: 'Acreditación inicial', href: '#' } ]}})],1)],1),_c('b-row',[_c('b-col',[_c('h1',{staticClass:"initial-accreditation-form__h1 float-left"},[_vm._v(" Acreditación Inicial ")])])],1),_c('b-row',[_c('b-col',[_c('p',{staticClass:"initial-accreditation-form__title-paragraph float-left"},[_c('a',[_vm._v("Descarga aquí los documentos")]),_vm._v(", firmalos, escanéalos y luego súbelos a nuestra platafoma desde el botón \"seleccionar archivo\". ")])])],1),_vm._l((_vm.unApprovedDocuments),function(document){return _c('div',{key:document.id,staticClass:"initial-accreditation-form__item"},[_c('b-row',{staticClass:"initial-accreditation-form__item__form-title"},[_c('b-col',{attrs:{"cols":"12","lg":"10"}},[_c('h2',[_vm._v(" "+_vm._s(document.name)+" ")])])],1),(document)?_c('b-row',{staticClass:"initial-accreditation-form__item__form-content"},[_c('b-col',{attrs:{"cols":"12","lg":"5"}},[_c('b-form-file',{attrs:{"value":document.binaryFiles,"placeholder":"Seleccionar archivo","drop-placeholder":"Seleccionar archivo","multiple":""},on:{"input":function($event){return _vm.onUploadDocumentFile(document, $event)}}}),_c('p',{staticClass:"file-description"},[_vm._v(" Puedes subir varios archivos. Peso máximo por archivo 100mb. Formatos permitidos .rar .zip .doc .docx .pdf .jpg... ")]),(
                  document &&
                  document.files &&
                  document.files.length &&
                  document.files.length > 0
                )?_c('div',{staticClass:"file-list"},_vm._l((document.files),function(file,index){return _c('span',{key:index},[_c('img',{attrs:{"src":require("@/assets/paperclip.png"),"alt":"Clip de papel"}}),_vm._v(_vm._s(file ? file.name : ""))])}),0):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","lg":"5"}},[_c('label',{attrs:{"for":"f-30"}},[_vm._v("Descripción")]),_c('b-form-textarea',{attrs:{"id":"f-30","placeholder":"Escribe aquí...","value":document.description}})],1)],1):_vm._e()],1)}),_c('div',{staticClass:"actions-section"},[_c('b-row',[_c('b-col',{staticClass:"order-2 order-lg-1",attrs:{"cols":"12","lg":"6"}},[_c('b-link',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Volver")])],1),_c('b-col',{staticClass:"order-1 order-lg-2",attrs:{"cols":"12","lg":"6"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveDocument}},[_vm._v(" Guardar")])],1)],1)],1)],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }