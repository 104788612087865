import APIRequest from "./api.services";

export function getDashboardInfo({ slug, filters }) {
  var url;

  if (slug !== null) {
    url = `/api/docs/dashboard/${slug}/`;
  } else {
    url = "/api/docs/dashboard/";
  }

  return new Promise((resolve, reject) => {
    APIRequest.post(
      url,
      { filters: filters },
      ({ data }) => {
        resolve(data);
      },
      (response) => {
        reject(response);
      }
    );
  });
}

export function getSummary() {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios("/api/docs/dashboard/summary/", null, ({ data }) => {
      resolve(data);
    }),
      (response) => {
        reject(response);
      };
  });
}

export function getDocuments(parameters) {
  return new Promise((resolve, reject) => {
    APIRequest.post(
      `/api/docs/project/${parameters.projectSlug}/document/`,
      parameters.filters,
      ({ data }) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function searchDocuments(parameters) {
  return new Promise((resolve, reject) => {
    APIRequest.post(
      `/api/docs/project/${parameters.projectSlug}/${parameters.companySlug}/search/`,
      parameters.filters,
      ({ data }) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function archiveProject(parameters) {
  return new Promise((resolve, reject) => {
    APIRequest.post(
      `/api/docs/project/${parameters.projectSlug}/archive/`,
      { active: parameters.active },
      ({ data }) => resolve(data),
      (error) => reject(error)
    );
  });
}

export function getProjects(parameter) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      "/api/docs/project/",
      parameter,
      ({ data }) => {
        resolve(data);
      },
      (error) => reject(error)
    );
  });
}

export function getProject(projectSlug) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      `/api/docs/project/${projectSlug}`,
      {},
      ({ data }) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getContractor(contractorSlug) {
  return new Promise((resolve, reject) => {
    APIRequest.getAxios(
      `/api/docs/contractor/${contractorSlug}`,
      {},
      ({ data }) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  getDashboardInfo,
  getDocuments,
  getProjects,
  getProject,
  searchDocuments,
  getContractor,
  getSummary,
  archiveProject,
};
