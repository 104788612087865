<template>
  <b-card
    title="Recuperar contraseña"
    class="login__card login__card--password"
  >
    <p>
      Ingresa tu email. Revisa tu bandeja de entrada, recibirás un mensaje con
      instrucciones sobre cómo restablecer tu contraseña.
    </p>
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-recover-password"
        label="Email"
        label-for="input-recover-password"
      >
        <b-form-input
          id="input-recover-password"
          type="email"
          placeholder="Ej: nombre@gmail.com"
          v-model="$v.form.email.$model"
          :state="validateState('email')"
        ></b-form-input>
        <b-form-invalid-feedback id="input-recover-password-live-feedback"
          >Ingresa tu email con este formato yourname@example.com
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="login__card__actions">
        <b-button
          type="submit"
          variant="primary"
          :disabled="passwordRecoveryLoading || $v.form.email.$invalid"
          >Continuar<b-spinner
            v-if="passwordRecoveryLoading"
            small
            label="Loading..."
          ></b-spinner
        ></b-button>
        <b-link
          @click="
            toggleLoginFormPasswordRecoveryFormChangePasswordForm({
              showChangePasswordForm: false,
              showLoginForm: true,
              showPasswordRecoveryForm: false,
            })
          "
          >Volver</b-link
        >
      </div>
    </b-form>
  </b-card>
</template>
<script>
import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "RecoverPasswordForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        email,
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    ...mapState("auth", ["passwordRecoveryLoading"]),
  },
  mounted() {
    this.setPasswordRecoveryLoading({ passwordRecoveryLoading: false });
  },
  methods: {
    ...mapActions("auth", [
      "passwordRecovery",
      "toggleLoginFormPasswordRecoveryFormChangePasswordForm",
    ]),
    ...mapMutations("auth", ["setPasswordRecoveryLoading"]),
    /** Realiza las validaciones del formulario */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /** Resetea la información del formulario */
    resetForm() {
      this.form = {
        username: null,
        password: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    /** Envía la información ingresada al formulario */
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.passwordRecovery({
        email: this.$v.form.email.$model,
      }).then(
        () => {
          this.$bvToast.toast(
            "Se ha enviado un correo con la inforamción para cambiar su contraseña",
            {
              title: "Autentificación",
              toaster: "b-toaster-top-right",
              solid: true,
              appendToast: false,
              variant: "success",
            }
          );
        },
        () => {
          this.$bvToast.toast("No se pudo actualizar su contraseña.", {
            title: "Autentificación",
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        }
      );
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.login {
  &__card {
    &--password {
      &.card {
        @media (min-width: $lg-min) {
          height: 418px;
        }
        .card-body {
          .card-title {
            @media (max-width: $lg) {
              margin-bottom: 18px;
            }
          }
          p {
            color: $light-grey;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 30px;
            @media (max-width: $lg) {
              margin-bottom: 55px;
            }
          }
          form .login__card__actions {
            position: absolute;
            width: calc(100% - 46px);
            bottom: 35px;
            @media (max-width: $lg) {
              position: relative;
              width: 100%;
              bottom: 0;
            }
            .btn.btn-primary {
              position: relative;
              width: 100%;
              bottom: 0;
              margin-bottom: 20px;
              @media (max-width: $lg) {
                margin-top: 48px;
              }
            }
            a {
              letter-spacing: 0.01em;
              text-transform: uppercase;
              color: $primary-color;
              font-weight: bold;
              font-size: 15px;
              line-height: 16px;
              text-align: center;
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
