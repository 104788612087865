<template>
  <div class="initial-accreditation-form">
    <b-container>
      <b-row>
        <b-col class="pl-0" v-if="windowsWidth < 992">
          <AppSidebar />
        </b-col>
        <b-col cols="12" v-if="project">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[
                  {
                    text: 'Inicio',
                    to: {
                      name: 'AssignedProjects',
                    },
                  },
                  {
                    text: 'Documentos ',
                    to: {
                      name: 'ProjectDocuments',
                    },
                    params: {
                      project_slug: project.slug,
                    },
                  },
                  { text: 'Acreditación inicial', href: '#' },
                ]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row>
            <b-col
              ><h1 class="initial-accreditation-form__h1 float-left">
                Acreditación Inicial
              </h1>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              ><p
                class="initial-accreditation-form__title-paragraph float-left"
              >
                <a>Descarga aquí los documentos</a>, firmalos, escanéalos y
                luego súbelos a nuestra platafoma desde el botón "seleccionar
                archivo".
              </p></b-col
            >
          </b-row>
          <!-- Inicio de los documentos -->
          <div
            class="initial-accreditation-form__item"
            v-for="document in unApprovedDocuments"
            :key="document.id"
          >
            <b-row class="initial-accreditation-form__item__form-title">
              <b-col cols="12" lg="10"
                ><h2>
                  {{ document.name }}
                </h2></b-col
              ></b-row
            >
            <b-row
              class="initial-accreditation-form__item__form-content"
              v-if="document"
            >
              <b-col cols="12" lg="5">
                <b-form-file
                  :value="document.binaryFiles"
                  placeholder="Seleccionar archivo"
                  drop-placeholder="Seleccionar archivo"
                  @input="onUploadDocumentFile(document, $event)"
                  multiple
                ></b-form-file>
                <p class="file-description">
                  Puedes subir varios archivos. Peso máximo por archivo 100mb.
                  Formatos permitidos .rar .zip .doc .docx .pdf .jpg...
                </p>
                <div
                  v-if="
                    document &&
                    document.files &&
                    document.files.length &&
                    document.files.length > 0
                  "
                  class="file-list"
                >
                  <span v-for="(file, index) in document.files" :key="index"
                    ><img src="@/assets/paperclip.png" alt="Clip de papel" />{{
                      file ? file.name : ""
                    }}</span
                  >
                </div></b-col
              ><b-col cols="12" lg="5"
                ><label for="f-30">Descripción</label>
                <b-form-textarea
                  id="f-30"
                  placeholder="Escribe aquí..."
                  :value="document.description"
                ></b-form-textarea></b-col
            ></b-row>
          </div>

          <!-- Acciones -->
          <div class="actions-section">
            <b-row>
              <b-col cols="12" lg="6" class="order-2 order-lg-1"
                ><b-link @click="$router.go(-1)">Volver</b-link></b-col
              ><b-col cols="12" lg="6" class="order-1 order-lg-2"
                ><b-button variant="primary" @click="saveDocument">
                  Guardar</b-button
                ></b-col
              ></b-row
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import { mapState } from "vuex";
import router from "@/router";
import tablesServices from "@/services/tables.services";
import documentsServices from "@/services/documents.services";
import { updateDocuments } from "@/utils/documents";

export default {
  name: "InitialAccreditationForm",
  components: {
    AppSidebar,
  },
  data() {
    return {
      project: null,
      documents: [],
    };
  },
  computed: {
    ...mapState("front", ["windowsWidth"]),
    unApprovedDocuments() {
      const filter = (document) =>
        !(
          document &&
          document.document &&
          document.document.status == "approved"
        );
      return this.documents.filter(filter);
    },
  },
  mounted() {
    let step1 = tablesServices.getProject(this.$route.params.project_slug);
    let step2 = documentsServices.getAccreditationDocuments({
      project_slug: this.$route.params.project_slug,
    });

    Promise.all([step1, step2]).then((values) => {
      this.project = values[0];
      this.documents = values[1];
    });
  },
  methods: {
    onUploadDocumentFile(document, binaryFiles) {
      documentsServices
        .uploadDocFile({ files: binaryFiles, projectId: this.project.id })
        .then((documents) => {
          this.documents = updateDocuments(
            this.documents,
            document,
            binaryFiles,
            documents
          );
        });
    },
    notifyUpload() {
      let has_non_uploaded = this.documents.filter(
        (document) => document.files.length === 0
      );

      if (has_non_uploaded.length > 0) {
        this.$bvToast.toast(
          "Se han guardado los archivos de acreditación.  Aún tienes cargas pendientes.",
          {
            title: "Acreditación Inicial",
            toaster: "b-toaster-top-center",
            solid: true,
            appendToast: false,
            variant: "warning",
          }
        );
      } else {
        this.$bvToast.toast("Se han guardado los archivos de acreditación", {
          title: "Acreditación Inicial",
          toaster: "b-toaster-top-center",
          solid: true,
          appendToast: false,
          variant: "success",
        });
      }
    },
    saveDocument() {
      const params = {
        project_id: this.project.id,
        stage: "initial",
        company_id: null,
        period: null,
        documents: this.documents,
      };

      documentsServices.uploadDocument(params).then(() => {
        router
          .push({
            name: "ProjectAccreditationDocuments",
            params: { project_slug: this.project.slug },
          })
          .then(() => {
            this.notifyUpload();
          });
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.initial-accreditation-form {
  text-align: center;
  &__h1 {
    margin-top: 20px;
    @media (max-width: $lg) {
      margin-top: 0;
    }
  }
  &__title-paragraph {
    margin-top: 10px;
    @media (max-width: $lg) {
      text-align: left;
    }
    a,
    a:not([href]):not([class]) {
      text-decoration: underline;
      color: $primary-color;
      font-weight: bold;
    }
  }
  &__item {
    padding-top: 30px;
    padding-bottom: 50px;
    border-bottom: 1px solid #d9d9d9;
    @media (max-width: $lg) {
      padding-bottom: 30px;
    }
    &--last {
      border-bottom: none;
    }
    &__form-title,
    &__form-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: left;
    }
    &__form-title {
      h2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 18px;
        color: $dark-grey;
      }
    }
    &__form-content {
      .col-5 {
        @media (min-width: $lg-min) {
          &:first-of-type {
            padding-right: 30px;
          }
          &:last-of-type {
            padding-left: 30px;
          }
        }
      }
      .btn-secondary {
        margin-bottom: 20px;
        width: 100%;
        @media (min-width: $lg-min) {
          width: 288px;
          margin-top: 5px;
        }
      }
      .file-description {
        font-size: 13px;
        line-height: 15px;
        color: $dark-grey;
      }
      label {
        font-size: 15px;
        line-height: 15px;
        font-weight: bold;
        color: $darkest-grey;
        @media (max-width: $lg) {
          opacity: 0;
        }
      }
    }
  }
  .actions-section {
    padding-top: 99px;
    padding-bottom: 74px;
    @media (max-width: $lg) {
      padding-top: 20px;
      padding-bottom: 112px;
    }
  }
}
</style>
