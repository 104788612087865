var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"initial-accreditation-form edit-document-form"},[_c('b-container',[_c('b-row',[(_vm.windowsWidth < 992)?_c('b-col',{staticClass:"pl-0"},[_c('AppSidebar')],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[(_vm.project)?_c('b-row',[_c('b-col',[_c('b-breadcrumb',{staticClass:"mt-2",attrs:{"items":[
                {
                  text: 'Inicio',
                  to: {
                    name: 'AssignedProjects',
                  },
                },
                {
                  text: 'Documentos',
                  to: {
                    name: 'ProjectDocuments',
                  },
                  params: {
                    project_slug: _vm.project.slug,
                  },
                },
                { text: 'Editar documento', href: '#' } ]}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('h1',{staticClass:"initial-accreditation-form__h1 float-left"},[_vm._v(" Editar documentos ")])])],1),(_vm.project && _vm.document)?_c('b-row',{staticClass:"\n            justify-content-center\n            mt-5\n            mb-4\n            initial-accreditation-form__upper-select\n          "},[_c('b-col',{attrs:{"cols":"12","lg":"10"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('h2',{staticClass:"initial-accreditation-form__h2 float-left"},[_vm._v(" "+_vm._s(_vm.project.name)+" ")])])],1)],1)],1):_vm._e(),(_vm.document)?_c('div',{staticClass:"initial-accreditation-form__item"},[_c('b-row',{staticClass:"initial-accreditation-form__item__form-title"},[_c('b-col',{attrs:{"cols":"12","lg":"10"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.document.document_type)+" ")])])],1),_c('b-row',{staticClass:"initial-accreditation-form__item__form-content"},[_c('b-col',{attrs:{"cols":"12","lg":"7"}},[(
                  _vm.document.files &&
                  _vm.document.files.length &&
                  _vm.document.files.length > 0
                )?_c('div',{staticClass:"file-list"},_vm._l((_vm.document.files),function(file,index){return _c('span',{key:index,staticClass:"link_file",on:{"click":function($event){$event.preventDefault();return _vm.downloadfile(file.url)}}},[_c('img',{attrs:{"src":require("@/assets/paperclip.png"),"alt":"Clip de papel"}}),_vm._v(_vm._s(file ? file.name : ""))])}),0):_vm._e()]),_c('b-col',{attrs:{"cols":"12","lg":"3"}})],1)],1):_vm._e(),_c('div',{staticClass:"actions-section"},[_c('b-row',[_c('b-col',{staticClass:"order-2 order-lg-1",attrs:{"cols":"12","lg":"6"}},[_c('b-link',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Volver")])],1),_c('b-col',{staticClass:"order-1 order-lg-2",attrs:{"cols":"12","lg":"6"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }