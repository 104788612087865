<template>
  <div class="initial-accreditation-form">
    <b-container>
      <b-row>
        <b-col class="pl-0" v-if="windowsWidth < 992">
          <AppSidebar />
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col
              ><b-breadcrumb
                class="mt-2"
                :items="[
                  { text: 'Inicio', href: '/#/contractor/assigned-projects' },
                  { text: 'Subir documentos', href: '#' },
                ]"
              ></b-breadcrumb
            ></b-col>
          </b-row>

          <b-row>
            <b-col
              ><h1 class="initial-accreditation-form__h1 float-left">
                Subir documento
              </h1>
            </b-col>
          </b-row>

          <b-row
            v-if="project"
            class="
              justify-content-center
              mt-5
              mb-4
              initial-accreditation-form__upper-select
            "
          >
            <b-col cols="12" lg="10">
              <b-row>
                <b-col cols="12" lg="8"
                  ><h2 class="initial-accreditation-form__h2 float-left">
                    {{ project.name }}
                  </h2></b-col
                >
                <b-col cols="12" lg="4"
                  ><b-form-select
                    v-model="selectedProjectDate"
                    :options="periods"
                    value-field="period"
                    @change="changePeriod"
                  ></b-form-select
                ></b-col>
              </b-row> </b-col
          ></b-row>

          <!-- Inicio de los documentos -->
          <div
            class="initial-accreditation-form__item"
            v-for="document in documents"
            :key="document.id"
          >
            <b-row class="initial-accreditation-form__item__form-title">
              <b-col cols="12" lg="10"
                ><h2>
                  {{ document.name }}
                </h2></b-col
              ></b-row
            >
            <b-row
              class="initial-accreditation-form__item__form-content"
              v-if="document"
            >
              <b-col cols="12" lg="5">
                <b-form-file
                  :disabled="
                    document.document && document.document.status == 'approved'
                  "
                  :value="document.binaryFiles"
                  placeholder="Seleccionar archivo"
                  drop-placeholder="Seleccionar archivo"
                  @input="onUploadDocumentFile(document, $event)"
                  multiple
                ></b-form-file>
                <p class="file-description">
                  Puedes subir varios archivos. Peso máximo por archivo 100mb.
                  Formatos permitidos .rar .zip .doc .docx .pdf .jpg...
                </p>
                <div
                  v-if="
                    document &&
                    document.files &&
                    document.files.length &&
                    document.files.length > 0
                  "
                  class="file-list"
                >
                  <span v-for="(file, index) in document.files" :key="index"
                    ><img src="@/assets/paperclip.png" alt="Clip de papel" />{{
                      file ? file.name : ""
                    }}</span
                  >
                </div></b-col
              ><b-col cols="12" lg="5"
                ><label for="f-30">Descripción</label>
                <b-form-textarea
                  id="f-30"
                  placeholder="Escribe aquí..."
                  :value="document.description"
                ></b-form-textarea></b-col
            ></b-row>
          </div>

          <!-- Acciones -->
          <div class="actions-section" v-if="documents.length > 0">
            <b-row>
              <b-col cols="12" lg="6" class="order-2 order-lg-1"
                ><b-link @click="$router.go(-1)">Cancelar</b-link></b-col
              ><b-col cols="12" lg="6" class="order-1 order-lg-2"
                ><b-button variant="primary" @click="saveDocument">
                  Guardar</b-button
                ></b-col
              ></b-row
            >
          </div>
          <div class="actions-section" v-if="documents.length == 0">
            No tienes documentos por subir en este mes
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppSidebar from "@/components/AppSidebar.vue";
import { mapState } from "vuex";
import router from "@/router";
import documentsServices from "@/services/documents.services";
import tablesServices from "@/services/tables.services";
import { updateDocuments } from "@/utils/documents";

export default {
  name: "UploadDocumentForm",
  components: {
    AppSidebar,
  },
  computed: {
    ...mapState("front", ["windowsWidth"]),
  },
  data: function () {
    return {
      selectedProjectDate: null,
      periods: [],
      documents: [],
      project: null,
    };
  },
  mounted() {
    var p1 = documentsServices.loadPeriods();
    var p2 = tablesServices.getProject(this.$route.params.project_slug);

    Promise.all([p1, p2]).then((values) => {
      this.periods = values[0];
      this.selectedProjectDate = this.periods[0].period;

      this.project = values[1];
      documentsServices
        .getContractorDocumentTypes({
          stage: "monthly",
          period: this.periods[0].period,
          project_slug: this.$route.params.project_slug,
        })
        .then((documents) => {
          this.documents = documents;
        });
    });
  },
  methods: {
    changePeriod(period) {
      documentsServices
        .getContractorDocumentTypes({
          stage: "monthly",
          period: period,
          project_slug: this.$route.params.project_slug,
        })
        .then((documents) => {
          this.documents = documents;
        });
    },
    checkFormItemByAccreditationId(id) {
      return this.initialAccreditation.find((item) => item.id === id);
    },
    onUploadDocumentFile(document, binaryFiles) {
      documentsServices
        .uploadDocFile({ files: binaryFiles, projectId: this.project.id })
        .then((documents) => {
          this.documents = updateDocuments(
            this.documents,
            document,
            binaryFiles,
            documents
          );
        });
    },
    notifyUpload() {
      let has_non_uploaded = this.documents.filter(
        (document) => document.files.length === 0
      );

      if (has_non_uploaded.length > 0) {
        this.$bvToast.toast(
          "Se han guardado los archivos del mes.  Aún tienes cargas pendientes.",
          {
            title: "Acreditación Inicial",
            toaster: "b-toaster-top-center",
            solid: true,
            appendToast: false,
            variant: "warning",
          }
        );
      } else {
        this.$bvToast.toast("Se han guardado los archivos del mes", {
          title: "Acreditación Inicial",
          toaster: "b-toaster-top-center",
          solid: true,
          appendToast: false,
          variant: "success",
        });
      }
    },
    saveDocument() {
      const params = {
        project_id: this.project.id,
        company_id: null,
        stage: "monthly",
        period: parseInt(this.selectedProjectDate, 10),
        documents: this.documents,
      };

      documentsServices.uploadDocument(params).then(() => {
        router.push({ name: "AssignedProjects" }).then(() => {
          this.notifyUpload();
        });
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variables";

.initial-accreditation-form {
  text-align: center;
  &__h1 {
    margin-top: 20px;
    @media (max-width: $lg) {
      margin-top: 0;
    }
  }
  &__title-paragraph {
    margin-top: 10px;
    @media (max-width: $lg) {
      text-align: left;
    }
    a,
    a:not([href]):not([class]) {
      text-decoration: underline;
      color: $primary-color;
      font-weight: bold;
    }
  }
  &__item {
    padding-top: 30px;
    padding-bottom: 50px;
    border-bottom: 1px solid #d9d9d9;
    @media (max-width: $lg) {
      padding-bottom: 30px;
    }
    &--last {
      border-bottom: none;
    }
    &__form-title,
    &__form-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: left;
    }
    &__form-title {
      h2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 18px;
        color: $dark-grey;
      }
    }
    &__form-content {
      .col-5 {
        @media (min-width: $lg-min) {
          &:first-of-type {
            padding-right: 30px;
          }
          &:last-of-type {
            padding-left: 30px;
          }
        }
      }
      .btn-secondary {
        margin-bottom: 20px;
        width: 100%;
        @media (min-width: $lg-min) {
          width: 288px;
          margin-top: 5px;
        }
      }
      .file-description {
        font-size: 13px;
        line-height: 15px;
        color: $dark-grey;
      }
      label {
        font-size: 15px;
        line-height: 15px;
        font-weight: bold;
        color: $darkest-grey;
        @media (max-width: $lg) {
          opacity: 0;
        }
      }
    }
  }
  .actions-section {
    padding-top: 99px;
    padding-bottom: 74px;
    @media (max-width: $lg) {
      padding-top: 20px;
      padding-bottom: 112px;
    }
  }
}
</style>
